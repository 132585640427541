import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect, useRef } from "react";
import { Card, CardContent, Dialog, DialogContent, DialogActions, DialogContentText, Box, Table, TableBody, TableRow, TableCell, Toolbar, Typography, Button, Select, MenuItem, Tabs, Tab, IconButton} from '@mui/material';
import { UserA } from "../user/user";
import TextField from '@mui/material/TextField';
import * as api from '../api/api'
import Loading from "../components/loading";
import PageLoading from "../components/pageloading";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Footer from '../components/footer';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { useTheme } from '@mui/material/styles';
import Plotly from 'plotly.js-dist';
import Tooltip from '@mui/material/Tooltip';
import PageContainer from '../components/PageContainer';
import InfoBlock from '../socket/InfoBlock';
import { CSVLink } from 'react-csv';
import PaperWrap from "../components/paper-wrap";
import { TabPanel, a11yProps } from '../components/tab';
import 'reactjs-popup/dist/index.css';
import CloseIcon from '@mui/icons-material/Close';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import * as constants from '../constants';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Chip from '@mui/material/Chip';
import FractionalUnitDisplay from "../components/FractionalUnitDisplay";
import FractionalUnitDisplay2 from "../components/FractionalUnitDisplay2";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FaTimes } from 'react-icons/fa';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../components/Calendar.css';
import { render } from "@testing-library/react";


let alertMessage = ''

const SuccessModal = ({ isOpen, onClose, Status, ManualTuneMsg }) => {

  let h4Msg = ''
  let pMsg = ''
  switch (Status) {
    case 'ok':
      h4Msg = "Success Message"
      pMsg = "Successfully submitted the Configuration"
      break;
    case 'hashrate':
      h4Msg = "Failure Message"
      pMsg = "Empty Desired HashRate field"
      break;
    case 'powerconstraint':
      h4Msg = "Failure Message"
      pMsg = "Empty Power Constraint fields"
       break;
    case 'energyprice':
      h4Msg = "Failure Message"
      pMsg = "Empty Electricity Cost field"
      break;
    case 'invalidjths':
      h4Msg = "Failure Message"
      pMsg = "Please provide target efficiency"
      break;
    case 'invalidpower':
      h4Msg = "Failure Message"
      pMsg = "Please provide power in megawatts"
      break;
    case 'invalidrampupdown':
      h4Msg = "Failure Message"
      pMsg = "Please make sure Ramp up/down time is between 120 and 86400 seconds"
      break;
    case 'invalidtime':
      h4Msg = "Failure Message"
      pMsg = "Please ensure that 10 minutes gap between start and end time"
      break;
    case 'invalidtimestandby':
      h4Msg = "Failure Message"
      pMsg = "Please ensure that 5 minutes gap between start and end time"
      break;
    case 'invalidtime10':
      h4Msg = "Failure Message"
      pMsg = "Please ensure that start time should be 10 mins ahead of current time"
      break;
    case 'invalidtime5':
      h4Msg = "Failure Message"
      pMsg = "Please ensure that start time should be 5 mins ahead of current time"
      break;
    case 'invalidtimeformat':
      h4Msg = "Failure Message"
      pMsg = "Invalid time format"
      break;
    case 'invalidstarttime':
      h4Msg = "Failure Message"
      pMsg = "Please make sure start time is more than 10 mins ahead"
      break;
    case 'hrrange':
      h4Msg = "Failure Message"
      pMsg = "Can't tune because desired hashrate is in 5% range of running hashrate"
      break;
    case 'unknown':
      h4Msg = "Failure Message"
      pMsg = "Failed to perform the operation"
      break;
    case 'error':
      if (ManualTuneMsg === '' || ManualTuneMsg === undefined || ManualTuneMsg === null) {
        pMsg = "Response from the server is not as expected."
      } else {
        pMsg =  ManualTuneMsg
      }
      break;
    case 'minergroup':
        h4Msg = "Failure Message"
        pMsg = "Please select miner group"
        break;
    case 'minergroups':
        h4Msg = "Failure Message"
        pMsg = "Empty Group List"
        break;
    case 'manualtuneunknown':
      h4Msg = "Failure Message"
      pMsg = "Failed to perform the operation"
      break;
    case 'manualtuneok':
      h4Msg = "Success Message"
      pMsg =  ManualTuneMsg
      break;
    case 'manualtuneerror':
      h4Msg = "Failure Message"
      pMsg =  ManualTuneMsg
      break;
    case 'fetchfailed':
      h4Msg = "Fetch Failed!"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
    case 'nouser':
        h4Msg = "Authentication Failed!"
        pMsg = "User is not part of the Organization. Please contact your Administrator"
      break;
    case 'noauth':
        h4Msg = "Authorization Failed!"
        pMsg = "User doesn't have permission to view this page"
        break;
    default:
      h4Msg = "Unknown Error"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
  }
  if (Status !== 'ok') {
  return (
    <Popup className="popup1-content" open={isOpen} onClose={onClose}>
      <h4 className="popup-title">{h4Msg}</h4>
      <div className="gap"></div>
      {typeof pMsg === 'object' ? (
        <div className="success-message-container">
          {Object.keys(pMsg).map((group, index) => (
            <div key={index} className="group-message">
              <span className="group-name">{group}:</span>
              <span
                className="group-content"
                dangerouslySetInnerHTML={{
                  __html: pMsg[group].replace(
                    /([a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12})/gi,
                    '<span class="job-id">$1</span>'
                  ),
                }}
              ></span>
            </div>
          ))}
        </div>
      ) : (
        <p className="success-message">{pMsg}</p>
      )}
      <div className="button-container">
        <button onClick={onClose}>Close</button>
      </div>
    </Popup>
  );
  }
};

let validUser = -1

const timeFormat = async (timevalue) => {

  const date = new Date(timevalue);

  // Get the components of the date
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Construct the formatted date string
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;

  return formattedDate

}

function generateRandomEfficiencyInRange(min, max) {
  return min + Math.random() * (max - min);
}

function generateRandomData(numDevices, inputTHs, inputEfficiency) {
  // Create an array to store the generated data
  const generatedData = [];

  // Generate data for the first device based on the input values
  generatedData.push({ THs: inputTHs, efficiency: inputEfficiency });

  // Generate data for the remaining devices
  for (let i = 1; i < numDevices; i++) {
    // Generate a random efficiency value within a range close to the input efficiency
    const minEfficiency = Math.max(inputEfficiency - 2, 25); // Adjust the range as needed
    const maxEfficiency = Math.min(inputEfficiency + 2, 27); // Adjust the range as needed
    const randomEfficiency = generateRandomEfficiencyInRange(minEfficiency, maxEfficiency);

    // Calculate the random TH/s value to maintain the same efficiency ratio as the input
    const randomTHs = inputTHs * (inputEfficiency / randomEfficiency);

    generatedData.push({ THs: randomTHs, efficiency: randomEfficiency });
  }

  return generatedData;
}

// Calculate the average efficiency for the generated random data
function calculateAverageEfficiency(data) {
  const totalEfficiency = data.reduce((sum, device) => sum + device.efficiency, 0);
  const averageEfficiency = totalEfficiency / data.length;
  return averageEfficiency;
}

function formatDate(date) {
  //console.log("formatDate")
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  let hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const meridiem = (hours < 12) ? 'AM' : 'PM';

  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  return `${year}-${month}-${day} ${hours}:${minutes} ${meridiem}`;
}

function createEndDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  let hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const meridiem = (hours < 12) ? 'AM' : 'PM';

  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  return `${year}-${month}-${day} 11:59 PM`;
}

const getEvents = (dgInfo) => {
    const dgInfoCurrent = dgInfo
    const events = [];
    if (dgInfoCurrent !== undefined && dgInfoCurrent.status !== 'error') {
      if (dgInfoCurrent.curtailSchedule) {
        for (let i = 0; i < dgInfoCurrent.curtailSchedule.length; i++) {
          //console.log('getEvents current schedule', dgInfoCurrent.curtailSchedule[i])
          const sDate = new Date(dgInfoCurrent.curtailSchedule[i].start_time);
          const sDay = sDate.getDate();
          const sMonth = sDate.getMonth() + 1; // Month is zero-based, so add 1 to get the correct month
          const sYear = sDate.getFullYear();
          const startDay = `${sDay}/${sMonth}/${sYear}`;
          let eDate;
          let eDay;
          let eMonth;
          let eYear;
          let endDay;
          let id;
          if (dgInfoCurrent.curtailSchedule[i].end_time !== '') {
            eDate = new Date(dgInfoCurrent.curtailSchedule[i].end_time);
            eDay = eDate.getDate();
            eMonth = eDate.getMonth() + 1; // Month is zero-based, so add 1 to get the correct month
            eYear = eDate.getFullYear();
            endDay = `${eDay}/${eMonth}/${eYear}`;
          } else {
            eDate = new Date(createEndDate(sDate));
            eDay = eDate.getDate();
            eMonth = eDate.getMonth() + 1; // Month is zero-based, so add 1 to get the correct month
            eYear = eDate.getFullYear();
            endDay = `${eDay}/${eMonth}/${eYear}`;
          }
          let color;
          if (dgInfoCurrent.curtailSchedule[i].source === 'manual') {
            color = 'blue'
          } else {
            color = 'green'
          }
          id = dgInfoCurrent.curtailSchedule[i].id
          events.push({
            title: "ID: " + id + " Start: " + formatDate(sDate) + "  End: " + formatDate(eDate) + "  Power: " + dgInfoCurrent.curtailSchedule[i].target_power_usage + "mW",
            power: dgInfoCurrent.curtailSchedule[i].target_power_usage,
            id: id,
            startDay: startDay,
            endDay: endDay,
            start: sDate,
            end: eDate,
            endSpecified: dgInfoCurrent.curtailSchedule[i].end_time !== '' ? true : false,
            color: color,
            source: dgInfoCurrent.curtailSchedule[i].source,
          })
        }
        return events;
      }
    }
    return [];
}

moment.locale('en-GB');
const localizer = momentLocalizer(moment);

/*
 * Calendar component
 */
const ACalendar = ({ forwardedRef, dgInfoMinerGroup, groupName, onEvent }) => {
  const [calevents, setCalEvents] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState('');
  const [slot, setSlot] = useState();
  const [color, setColor] = useState('default');
  const [update, setUpdate] = useState();
  const { user: user0, getAccessTokenSilently } = useAuth0();
  const [submitMsg, setSubmitMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  // //console.log("in ACalendar", JSON.stringify(dgInfoMinerGroup), groupName)

  useEffect(() => {
    const fetchEvents = () => {
      const events =  getEvents(dgInfoMinerGroup);
      setCalEvents(events);
    };
    fetchEvents();
    setIsDeleted(false);
  }, []);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      const fetchEvents = () => {
        const events =  getEvents(dgInfoMinerGroup);
        setCalEvents(events);
      };
      fetchEvents();
    }, 15000);
    return () => clearInterval(refreshInterval);
  }, [calevents]);

  const addNewEventAlert = (slotInfo) => {
    setOpen(true);
    setSlot(slotInfo);
    setIsDeleted(false);
  };
  const addNew = () => {
    setOpen(true);
    setIsDeleted(false);
  }
  const editEvent = (event) => {
    setOpen(true);
    const newEditEvent = calevents.find((elem) => elem.title === event.title);
    setColor(event.color);
    setTitle(newEditEvent.title);
    setColor(newEditEvent.color);
    setUpdate(event);
    setIsDeleted(false);
  };
  const updateEvent = (e) => {
    e.preventDefault();
    setCalEvents(
      calevents.map((elem) => {
        if (elem.title === update.title) {
          return { ...elem, title, color };
        }
        return elem;
      }),
    );
    setOpen(false);
    setTitle('');
    setColor('');
    setUpdate(null);
    setIsDeleted(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    alertMessage = ''
    const newEvents = calevents;
    //console.log('submitHandler ', slot.start, slot.end)
    newEvents.push({
      title,
      start: slot.start,
      end: slot.end,
      color,
    });
    setOpen(false);
    e.target.reset();
    setCalEvents(newEvents);
    setTitle('');
    setIsDeleted(false);
  };

  const refresh = () => {
    //console.log("refresh")
    //console.log(dgInfoMinerGroup)
    const events = getEvents(dgInfoMinerGroup);
    //console.log('Refresh events ', events, ' for minergroup ', dgInfoMinerGroup)
    setCalEvents(events);
    setIsDeleted(false);
  };

  const subHandler = (titleGiven, power, start, end, startDay, endDay, color, source) => {
    setIsDeleted(false);
    //console.log('in subHandler ', titleGiven, ' times ', start, end)
    alertMessage = ''
    let newEvents = calevents;
    let dayEvents = 0;
    let startDate = new Date(start).getTime();
    let endDate = new Date(end).getTime();
    for (let i = 0; i < newEvents.length; i++) {
      let eventStartDate = new Date(newEvents[i].start).getTime();
      let eventEndDate = new Date(newEvents[i].end).getTime();
      if (newEvents[i].startDay === startDay) {
        dayEvents++;
      }
      if (
        newEvents[i].startDay === startDay ||
        newEvents[i].endDay === endDay ||
        newEvents[i].startDay === endDay ||
        newEvents[i].endDay === startDay
      ) {
        // Check if new event overlaps with existing event
        if (
          (startDate < eventEndDate && endDate > eventStartDate) ||  // Overlaps with existing event
          (endDate > eventStartDate && startDate < eventEndDate)     // Overlaps with existing event
        ) {
          alertMessage = 'New event cannot overlap with the existing events';
          return alertMessage, false;
        }

        // Check if the new event starts within 5 minutes of existing event end
        if (
          startDate >= eventEndDate && startDate < eventEndDate + 5 * 60 * 1000
        ) {
          alertMessage = 'Please create a new event 5 mins apart from the previous event end time';
          return alertMessage, false;
        }

        // Check if the new event ends within 5 minutes of existing event start
        if (
          endDate > eventStartDate - 5 * 60 * 1000 && endDate <= eventStartDate
        ) {
          alertMessage = 'Please create a new event 5 mins apart from the previous event start time';
          return alertMessage, false;
        }
      }
    }
    // Check maximum events per day - REVISIT!!!
    if (dayEvents >= constants.MAX_SCHEDULE_EVENTS) {
      alertMessage = 'You can add only 15 events per day';
      return 'You can add only 15 events per day', false;
    }
    newEvents.push({
      title: titleGiven,
      power: power,
      startDay: startDay,
      endDay: endDay,
      start: new Date(start),
      end: new Date(end),
      color: color,
      source: source,
    });

    setCalEvents(newEvents);
    setOpen(false);
    setTitle(titleGiven);
    // setUpdate(null); /* REVISIT */

    return "", true;

  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    let hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const meridiem = (hours < 12) ? 'AM' : 'PM';

    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${year}-${month}-${day} ${hours}:${minutes} ${meridiem}`;
  }

  React.useImperativeHandle(forwardedRef, () => (
    {
    subHandler, addNew, refresh
    }
  ));


  const handleDeleteCurtailSchedule = async (event) => {
    //console.log('delete power curtail schedule', event)

    setIsLoading(true);
    setIsDeleted(false);
    let schedule_id = ''
    if (!event.hasOwnProperty('id') || event.id === undefined || event.id === null) {
      setSubmitMsg('Event ID is not available. Please refresh the page and try again.')
      setIsLoading(false);
      return;
    }
    schedule_id = event.id
    const token = await getAccessTokenSilently();
    const info = {}
    info.dgname = groupName
    info.id = schedule_id
    const result = await api.DeletePowerCurtail(token, info);
    //console.log('delete power curtail result', result)
    if (result === undefined || result === null) {
        setSubmitMsg('Failed to perform the operation')
    } else if (result.status === 'error') {
        setSubmitMsg(result.msg)
    } else {
      setIsDeleted(true);
      const updatecalEvents = calevents.filter((ind) => ind.title !== event.title);
      //console.log('updatecalEvents', JSON.stringify(updatecalEvents))
      //console.log('updated event after delete', update)
      setCalEvents(updatecalEvents);
      setSubmitMsg(result.msg)
      //console.log('delete power curtail dginfo', dgInfoMinerGroup)
      for (let i = 0; i < dgInfoMinerGroup.curtailSchedule.length; i++) {
        if (dgInfoMinerGroup.curtailSchedule[i].id === schedule_id) {
            dgInfoMinerGroup.curtailSchedule.splice(i, 1);
            break;
        }
      }
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    onEvent(update);
    setOpen(false);
    setTitle('');
    alertMessage = ''
    setUpdate(null);
  };

  const eventColors = (event) => {
    if (event.color) {
      return { className: `event-${event.color}` };
    }
    return { className: `event-default` };
  };


  return (
    <PageContainer title="Power Management" description="this is Calendar page">
      <Card>
        <CardContent>
          <Calendar
            selectable
            events={calevents}
            views={['day', 'week', 'month']}
            defaultView="month"
            scrollToTime={new Date(1970, 1, 1, 6)}
            defaultDate={new Date()}
            localizer={localizer}
            style={{ height: 'calc(100vh - 350px' }}
            onSelectEvent={(event) => editEvent(event)}
            onSelectSlot={(slotInfo) => addNewEventAlert(slotInfo)}
            eventPropGetter={(event) => eventColors(event)}
          />
        </CardContent>
      </Card>
      {  (update !== undefined && update !== null ) ? (
      <Dialog open={open} onClose={handleClose} fullWidth>
        <form onSubmit={update ? updateEvent : submitHandler}>
          <DialogContent>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Delete This Event?
              </Typography>
            <div style={{ display: 'flex', direction: 'row'}}>
            <Typography variant="h7"> Event ID:</Typography>
            <Typography variant="h7" sx={{ml: 2, fontWeight: 'bold'}}>
              {update ? update.id : ''}
            </Typography>
            </div>
            <div style={{ display: 'flex', direction: 'row'}}>
            <Typography variant="h7"> Start Time:</Typography>
            <Typography variant="h7" sx={{ml: 2}}>
              {update ? formatDate(update.start) : ''}
            </Typography>
            </div>
            <div style={{ display: 'flex', direction: 'row'}}>
            <Typography variant="h7"> End Time:</Typography>
            <Typography variant="h7" sx={{ml: 2}}>
              {update ? formatDate(update.end) : ''}
            </Typography>
            </div>
            <div style={{ display: 'flex', direction: 'row'}}>
            <Typography variant="h7"> Power:</Typography>
            <Typography variant="h7" sx={{ml: 2}}>
              {update ? update.power + "mW" : ''}
            </Typography>
            </div>
            <div style={{ display: 'flex', direction: 'row'}}>
            <Typography variant="h7"> Source:</Typography>
            <Typography variant="h7" sx={{ml: 2}}>
              {update ? update.source : ''}
            </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            {(update && !isDeleted) ? (
              <Button
                  onClick={() => {
                    handleDeleteCurtailSchedule(update);
                  }}
                  disabled={isLoading}
                  type="submit"
                  variant="contained"
                  color="error"
              >
              {isLoading ? 'In Progress...' : 'Delete'}
            </Button>
              ) : ('')
            }
          </DialogActions>
          {submitMsg && (
            <DialogContent>
              <DialogContentText>{submitMsg}</DialogContentText>
            </DialogContent>
          )}
        </form>
      </Dialog>
      ) : ('')}
    </PageContainer>
  );
};

const CURTAIL_MODE = 'curtailMode';
const CURTAILNOW_MODE = 'curtailnowMode';
const STANDBY_MODE = 'curtailstandbyMode';
const DASHBOARD_V2_TAB = 'dashboardV2Tab';
const DASHBOARD_V2_GROUP = 'dashboardV2Group';

export const DashboardV2 = () => {

    const chartRef = useRef(null);
    const chartRef1 = useRef(null);

    const [inputIsPerGroup, setinputIsPerGroup] = useState(
      localStorage.getItem('inputIsPerGroup') === 'true' ? true : false
    );
    const [successModalOpen, setSuccessModalOpen] = useState(false);

    const [minerGroup, setMinerGroup] = useState(localStorage.getItem(DASHBOARD_V2_GROUP) || 'Default Group');
    const [minerGroups, setMinerGroups] = useState([]);

    const { user: user0, getAccessTokenSilently } = useAuth0();

    const [value, setTabValue] = React.useState(
      localStorage.getItem(DASHBOARD_V2_TAB) ? parseInt(localStorage.getItem(DASHBOARD_V2_TAB)) : 0
    );
    const [dgInfo, setDgInfo] = useState('');
    const [submitMsg, setSubmitMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isTabEnabled, setIsTabEnabled] = useState(true);
    const [startTimeValue, setStartTimeValue] = useState(dayjs().add(10, 'minute'));
    const [startTimeString, setStartTimeString] = useState('');
    const [endTimeValue, setEndTimeValue] = useState(dayjs().add(13, 'minute'));
    const [endTimeString, setEndTimeString] = useState('');
    const [changePowerValue, setChangePowerValue] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const [hashPriceMode, setHashPriceMode] = useState(false);
    const [nowMode, setNowMode] = useState(localStorage.getItem(CURTAILNOW_MODE) === 'true' ? true : false);
    const [scheduleMode, setScheduleMode] = useState(localStorage.getItem(CURTAIL_MODE) === 'true' ? true : false);
    const [standbyMode, setStandbyMode] = useState(localStorage.getItem(STANDBY_MODE) === 'true' ? true : false);

    const [displayHashPrice, setDisplayHashPrice] = useState('');
    const [currentHashPrice, setCurrentHashPrice] = useState('');
    const [displayHashRate, setDisplayHashRate] = useState('');
    const [displayEnergyPrice, setDisplayEnergyPrice] = useState('');
    const [displayPowerUsage, setDisplayPowerUsage] = useState('');
    const [displayJTHs, setDisplayJTHs] = useState('');
    const [displayTotalDevices, setDisplayTotalDevices] = useState('');
    const [displayPPAType, setDisplayPPAType] = useState('');
    const [displayZone, setDisplayZone] = useState('');
    const [displayISO, setDisplayISO] = useState('');
    const [displayTotalActiveDevices, setDisplayTotalActiveDevices] = useState('');
    const [displayPowerMin, setUseFamilyPowerMin] = useState('');
    const [displayPowerMax, setUseFamilyPowerMax] = useState('');
    const [displayModelCount, setModelCount] = useState({});

    const [inputPreviewHashPrice, setinputPreviewHashPrice] = useState('');
    const [inputDesiredJTHS, setinputDesiredJTHS] = useState('');

    const [revenue, setRevenue] = useState('');
    const [powercost, setPowerCost] = useState('');
    const [profit, setProfit] = useState('');
    const [inputDesiredJTHSError, setinputDesiredJTHSError] = React.useState({
      error: false,
      helperText: "",
    })
    const [inputPreviewHashPriceError, setinputPreviewHashPriceError] = React.useState({
      error: false,
      helperText: "",
    })
    const [changePowerValueError, setchangePowerValueError] = React.useState({
      error: false,
      helperText: "",
    })
    const [changeRampUpDownValueError, setchangeRampUpDownTimeValueError] = React.useState({
      error: false,
      helperText: "",
    })

    const [changeRampUpDownTime, setChangeRampUpDownTime] = useState('');

    const calendarRef = useRef();

    const handleChangePowerValue = (event) => {
      const value = event.target.value;
      const floatRegex = /^(\d+(\.\d*)?|\.\d*)$/;

      if (value === '' || floatRegex.test(value)) {
        setChangePowerValue(value);
        setchangePowerValueError({ error: false, helperText: "" });
      } else {
        setchangePowerValueError({ error: true, helperText: "" });
      }
    };

    const handleChangeRampUpDownTimeValue = (event) => {
      const value = event.target.value;
      const intRegex = /^\d+$/
      if (value === '' || intRegex.test(value)) {
        setChangeRampUpDownTime(value);
        setchangeRampUpDownTimeValueError({ error: false, helperText: "" });
      } else {
        setchangeRampUpDownTimeValueError({ error: true, helperText: "" });
      }
    };

    const handleStartTimeValue = (newValue) => {
      if (newValue !== null && newValue !== undefined) {
        setStartTimeValue(newValue)
        let starttime = newValue['$d'].toString()
        if (starttime !== '' && starttime !== undefined && starttime !== null & starttime !== 'Invalid Date') {
          setStartTimeString(starttime)
          const tmpstart = new Date(starttime)
          tmpstart.setHours(tmpstart.getHours(), tmpstart.getMinutes()+10, 59, 999);
          const tmpend = new Date(tmpstart);
          setEndTimeString(tmpend)
          setEndTimeValue(dayjs(tmpend));
        } else {
          setStartTimeString('')
        }
      }
    }
    const handleEndTimeValue = (newValue) => {
      if (newValue !== null && newValue !== undefined) {
        //console.log('end time value', newValue)
        setEndTimeValue(newValue)
        let endtime = newValue['$d'].toString()
        if (endtime !== '' && endtime !== undefined && endtime !== null & endtime !== 'Invalid Date') {
          setEndTimeString(endtime)
          //console.log('end time', newValue['$d'].toString())
        } else {
          const startTime = new Date(startTimeString)
          startTime.setHours(23, 59, 59, 999);
          const endTime = new Date(startTime);
          // const startTime = new Date(startTimeString);
          // const endTime = new Date(startTime);
          // endTime.setHours(startTime.getHours() + 23, 59, 59, 999);
          //console.log("End Time:", endTime);
          setEndTimeString(endTime)
          setEndTimeValue(dayjs(endTime));
          //console.log("in the handle end time value", endTime)
        }
      }
    }

    const handleCalendarEvent = (eventData) => {
      // Handle the event data received from the calendar component
      //console.log("Event data received:", eventData);
      if (eventData !== undefined && eventData !== null) {
        if (eventData.hasOwnProperty('start') && eventData.start !== undefined && eventData.start !== null) {
          //console.log(dayjs(eventData.start));
          setStartTimeValue(dayjs(eventData.start));
        }
        // Only populate endTime if it has been specified.
        if (eventData.hasOwnProperty('endSpecified') && eventData.endSpecified && eventData.hasOwnProperty('end') && eventData.end !== undefined && eventData.end !== null) {
          setEndTimeValue(dayjs(eventData.end));
        }
        if (eventData.hasOwnProperty('power') && eventData.power !== undefined && eventData.power !== null) {
          setChangePowerValue(eventData.power);
        }
      }
    };

    const AutoTuneInterval = 3600

    const handleHashPriceMode = () => {
      setHashPriceMode(!hashPriceMode);
    }

    const handleScheduleMode = (event) => {
      // setScheduleMode(!scheduleMode);
      const newValue = event.target.value === 'true';
      setScheduleMode(newValue);
      // Store the new selected value in localStorage
      localStorage.setItem(CURTAIL_MODE, newValue.toString());
    }

    const handleStandbyMode = (event) => {
      const newValue = event.target.value === 'true';
      setStandbyMode(newValue);
      localStorage.setItem(STANDBY_MODE, newValue.toString());
    }

    const handleCloseSuccessModal = () => {
      setSuccessModalOpen(false);
    };

    const handleTabChange = (event, newValue) => {
      if (isLoading) {
        event.preventDefault();
      } else {
        setTabValue(newValue);
        localStorage.setItem(DASHBOARD_V2_TAB, newValue);
      }
    };
    const fetchDgInfo = async (resetValues, dgname) => {
        const value = dgname
        const token = await getAccessTokenSilently();
        const info = {}
        const isAllowed = await api.IsOperationAllowed(token);
        if (isAllowed === undefined) {
          validUser = 1
          setSubmitMsg('something')
          setSuccessModalOpen(true);
          return
        } else if (isAllowed.status === 'error') {
          if (isAllowed.errorCode === 1007) {
            validUser = 1
            setSubmitMsg('nouser')
            setSuccessModalOpen(true);
          } else if (isAllowed.errorCode === 1009) {
            validUser = 1
            setSubmitMsg('noauth')
            setSuccessModalOpen(true);
          } else {
            validUser = 1
            setSubmitMsg('fetchfailed')
            setSuccessModalOpen(true);
          }
          return
        }
        if (resetValues) {
          setDgInfo('')
        }
        if (value !== '') {
          info.dgName = value
          let op = 'groupCurtailData'
          const dgInfoCurrent = await api.GetDGInfo(token, info, op);
          console.log('fetchDgInfo', dgInfoCurrent)
          if (dgInfoCurrent === undefined) {
            validUser = 1
            if (resetValues) {
              setSubmitMsg('something')
              setSuccessModalOpen(true);
            }
          } else if (dgInfoCurrent.status === 'error') {
            if (dgInfoCurrent.errorCode === 1007) {
              validUser = 1
              if (resetValues) {
                setSubmitMsg('nouser')
                setSuccessModalOpen(true);
              }
            } else {
              validUser = 1
              if (resetValues) {
                setSubmitMsg('fetchfailed')
                setSuccessModalOpen(true);
              }
            }
          } else {
            let tmpHashPrice = 0.0
            let tmpHashRate = 0.0
            let tmpEnergyPrice = 0.0
            let tmpRevenue = 0.0
            let tmpPowerCost = 0.0
            let tmpProfit = 0.0
            let powerInMW = 0.0
            validUser = 0
            setSubmitMsg('ok')
            setinputPreviewHashPrice("")
            setDgInfo(dgInfoCurrent)
            if (dgInfoCurrent.hasOwnProperty('priceConfig') && dgInfoCurrent.priceConfig.hasOwnProperty('HashPrice')) {
              tmpHashPrice = parseFloat(dgInfoCurrent.priceConfig.HashPrice)
              setDisplayHashPrice(tmpHashPrice.toFixed(2))
              setCurrentHashPrice(tmpHashPrice.toFixed(2))
            }
            if (dgInfoCurrent.hasOwnProperty('priceConfig') && dgInfoCurrent.priceConfig.hasOwnProperty('EnergyPrice')) {
              tmpEnergyPrice = parseFloat(dgInfoCurrent.priceConfig.EnergyPrice)
              setDisplayEnergyPrice(tmpEnergyPrice.toFixed(2))
            }
            if (dgInfoCurrent.hasOwnProperty('priceConfig') && dgInfoCurrent.priceConfig.hasOwnProperty('PPAType')) {
                setDisplayPPAType(dgInfoCurrent.priceConfig.PPAType)
            }
            if (dgInfoCurrent.hasOwnProperty('priceConfig') && dgInfoCurrent.priceConfig.hasOwnProperty('Zone')) {
                setDisplayZone(dgInfoCurrent.priceConfig.Zone)
            }
            if (dgInfoCurrent.hasOwnProperty('priceConfig') && dgInfoCurrent.priceConfig.hasOwnProperty('ISO')) {
                setDisplayISO(dgInfoCurrent.priceConfig.ISO)
            }
            if (dgInfoCurrent.hasOwnProperty('summary') && dgInfoCurrent.summary.hasOwnProperty('aggregatedJperTHs')) {
              setDisplayJTHs(dgInfoCurrent.summary.aggregatedJperTHs)
            }
            if (dgInfoCurrent.hasOwnProperty('summary') && dgInfoCurrent.summary.hasOwnProperty('aggregatedTHs')) {
              tmpHashRate = parseFloat(dgInfoCurrent.summary.aggregatedTHs/1000) //Convert to PH/s
              setDisplayHashRate(tmpHashRate.toFixed(2))
            }
            let pMax = constants.AT1500_MAXPOWER
            let pMin = constants.MINPOWER
            if (dgInfoCurrent.hasOwnProperty('summary') && dgInfoCurrent.summary.hasOwnProperty('totalActiveDevices')) {
              setDisplayTotalActiveDevices(dgInfoCurrent.summary.totalActiveDevices)
            }
            if (dgInfoCurrent.hasOwnProperty('modelCount')) {
              setModelCount(dgInfoCurrent.modelCount)
              pMax = 0
              pMin = 0
              for (const [key, value] of Object.entries(dgInfoCurrent.modelCount)) {
                if (constants.PRODUCT_FAMILY.includes(key) &&
                    constants.PRODUCT_FAMILY_MAXPOWER[key] &&
                    value > 0) {
                      pMax += value * constants.PRODUCT_FAMILY_MAXPOWER[key]
                      pMin += value * constants.MINPOWER
                }
              }
            }
            if (pMax > 0) {
              pMax = pMax / 1000000.
              setUseFamilyPowerMax(pMax.toFixed(4))
            } else {
              pMax = constants.AT1500_MAXPOWER / 1000000.
              setUseFamilyPowerMax(pMax.toFixed(4))
            }
            if (pMin > 0) {
              pMin = pMin / 1000000.
              setUseFamilyPowerMin(pMin.toFixed(4))
            } else {
              pMin = constants.MINPOWER / 1000000.
              setUseFamilyPowerMin(pMin.toFixed(4))
            }
            if (dgInfoCurrent.hasOwnProperty('totalDevices')) {
              setDisplayTotalDevices(parseInt(dgInfoCurrent.totalDevices))
            }
            if (dgInfoCurrent.hasOwnProperty('summary') && dgInfoCurrent.summary.hasOwnProperty('aggregatedPowerConsumption')) {
              powerInMW = dgInfoCurrent.summary.aggregatedPowerConsumption / 1000000.
              powerInMW = powerInMW.toFixed(4)
              setDisplayPowerUsage(powerInMW)
            }
            tmpRevenue = tmpHashRate * tmpHashPrice
            tmpPowerCost = powerInMW * tmpEnergyPrice * 24
            tmpProfit = tmpRevenue - tmpPowerCost
            setRevenue(tmpRevenue.toFixed(2))
            if (tmpPowerCost === 0) {
                setPowerCost('0.00')
            } else {
                setPowerCost(tmpPowerCost.toFixed(2))
            }
            setProfit(tmpProfit.toFixed(2))
            setSuccessModalOpen(true);
          }
        }
    }

  const renderChart = () => {
    if (chartRef.current) {
        if (minerGroup === '') {
            chartRef.current.innerHTML = "";
            return;
        }
        if (dgInfo === '' || dgInfo === undefined || dgInfo === null || dgInfo.status === 'error') {
            chartRef.current.innerHTML = "";
            return;
        }
        if (dgInfo.hasOwnProperty('AutoTuneV2ChartData') === false) {
            chartRef.current.innerHTML = "";
            return;
        }
        if (dgInfo.AutoTuneV2ChartData === undefined || dgInfo.AutoTuneV2ChartData === null) {
            chartRef.current.innerHTML = "";
            return;
        }
        if (dgInfo.AutoTuneV2ChartData.hasOwnProperty('chartProfitData') === false) {
            chartRef.current.innerHTML = "";
            return;
        }
        if (dgInfo.AutoTuneV2ChartData.chartProfitData === undefined || dgInfo.AutoTuneV2ChartData.chartProfitData === null) {
            chartRef.current.innerHTML = "";
            return;
        }
        if (dgInfo.AutoTuneV2ChartData.chartProfitData.hasOwnProperty('energyPrice') === false ||
            dgInfo.AutoTuneV2ChartData.chartProfitData.hasOwnProperty('hashRate') === false ||
            dgInfo.AutoTuneV2ChartData.chartProfitData.hasOwnProperty('efficiency') === false ||
            dgInfo.AutoTuneV2ChartData.chartProfitData.hasOwnProperty('profit') === false) {
            chartRef.current.innerHTML = "";
            return;
        }
        const energyPrice = dgInfo.AutoTuneV2ChartData.chartProfitData.energyPrice
        const hashRate = dgInfo.AutoTuneV2ChartData.chartProfitData.hashRate
        const efficiency = dgInfo.AutoTuneV2ChartData.chartProfitData.efficiency
        const profit = dgInfo.AutoTuneV2ChartData.chartProfitData.profit
        if (energyPrice === undefined || energyPrice === null || energyPrice.length === 0 ||
            hashRate === undefined || hashRate === null || hashRate.length === 0 ||
            efficiency === undefined || efficiency === null || efficiency.length === 0 ||
            profit === undefined || profit === null || profit.length === 0) {
            chartRef.current.innerHTML = "";
            return;
        }
        chartRef.current.innerHTML = "";
        const energyPriceMin = Math.min(...energyPrice);
        const energyPriceMax = Math.max(...energyPrice);
        const profitMin = Math.min(...profit);
        const profitMax = Math.max(...profit);
        // Add buffer to the range for better visibility
        const energyPriceBuffer = (energyPriceMax - energyPriceMin) * 0.1; // 10% buffer
        const profitBuffer = (profitMax - profitMin) * 0.1; // 10% buffer

        let profitLine = {
            x: energyPrice,
            y: profit,
            mode: 'lines',
            name: 'Profit per day',
            line: {color: 'green'},
            hovertemplate: 'Energy Price: $%{x:.2f}<br>Profit: $%{y:.2f}<br>Hash Rate: %{customdata[0]:.2f} THs <br>Efficiency: %{customdata[1]:.2f} JTHs<extra></extra>',
            customdata: hashRate.map((rate, index) => [rate, efficiency[index]])
        };

        let chartData = [profitLine];

        const layout = {
            title: {
                font: { size: 16 },
                x: 0.5,
                y: 0.5,
                xref: 'paper',
                yref: 'paper',
            },
            xaxis: {
                title: 'Energy Price in USD per MWh',
                range: [energyPriceMin - energyPriceBuffer, energyPriceMax + energyPriceBuffer]
            },
            yaxis: {
                title: 'Profit in USD',
                range: [profitMin - profitBuffer, profitMax + profitBuffer]
            },
        };

        Plotly.newPlot(chartRef.current, chartData, layout);
    }
  };

  const renderChart1 = () => {
    if (chartRef1.current) {
        if (minerGroup === '') {
            chartRef1.current.innerHTML = "";
            return;
        }
        if (dgInfo === '' || dgInfo === undefined || dgInfo === null || dgInfo.status === 'error') {
            chartRef1.current.innerHTML = "";
            return;
        }
        if (dgInfo.hasOwnProperty('AutoTuneV2ChartData') === false) {
            chartRef1.current.innerHTML = "";
            return;
        }
        if (dgInfo.AutoTuneV2ChartData === undefined || dgInfo.AutoTuneV2ChartData === null) {
            chartRef1.current.innerHTML = "";
            return;
        }
        if (dgInfo.AutoTuneV2ChartData.hasOwnProperty('chartProfitData') === false) {
            chartRef1.current.innerHTML = "";
            return;
        }
        if (dgInfo.AutoTuneV2ChartData.chartProfitData === undefined || dgInfo.AutoTuneV2ChartData.chartProfitData === null) {
            chartRef1.current.innerHTML = "";
            return;
        }
        if (dgInfo.AutoTuneV2ChartData.chartProfitData.hasOwnProperty('energyPrice') === false ||
            dgInfo.AutoTuneV2ChartData.chartProfitData.hasOwnProperty('hashRate') === false ||
            dgInfo.AutoTuneV2ChartData.chartProfitData.hasOwnProperty('efficiency') === false ||
            dgInfo.AutoTuneV2ChartData.chartProfitData.hasOwnProperty('sellingBackProfit') === false) {
            chartRef1.current.innerHTML = "";
            return;
        }
        const energyPrice = dgInfo.AutoTuneV2ChartData.chartProfitData.energyPrice
        const hashRate = dgInfo.AutoTuneV2ChartData.chartProfitData.hashRate
        const efficiency = dgInfo.AutoTuneV2ChartData.chartProfitData.efficiency
        const profit = dgInfo.AutoTuneV2ChartData.chartProfitData.sellingBackProfit
        if (energyPrice === undefined || energyPrice === null || energyPrice.length === 0 ||
            hashRate === undefined || hashRate === null || hashRate.length === 0 ||
            efficiency === undefined || efficiency === null || efficiency.length === 0 ||
            profit === undefined || profit === null || profit.length === 0) {
            chartRef1.current.innerHTML = "";
            return;
        }
        chartRef1.current.innerHTML = "";
        const energyPriceMin = Math.min(...energyPrice);
        const energyPriceMax = Math.max(...energyPrice);
        const profitMin = Math.min(...profit);
        const profitMax = Math.max(...profit);
        // Add buffer to the range for better visibility
        const energyPriceBuffer = (energyPriceMax - energyPriceMin) * 0.1; // 10% buffer
        const profitBuffer = (profitMax - profitMin) * 0.1; // 10% buffer

        let profitLine = {
            x: energyPrice,
            y: profit,
            mode: 'lines',
            name: 'Profit per day',
            line: {color: 'green'},
            hovertemplate: 'Energy Price: $%{x:.2f}<br>Profit: $%{y:.2f}<br>Hash Rate: %{customdata[0]:.2f} THs <br>Efficiency: %{customdata[1]:.2f} JTHs<extra></extra>',
            customdata: hashRate.map((rate, index) => [rate, efficiency[index]])
        };

        let chartData = [profitLine];

        const layout = {
            title: {
                font: { size: 16 },
                x: 0.5,
                y: 0.5,
                xref: 'paper',
                yref: 'paper',
            },
            xaxis: {
                title: 'Energy Price in USD per MWh',
                range: [energyPriceMin - energyPriceBuffer, energyPriceMax + energyPriceBuffer]
            },
            yaxis: {
                title: 'Selling Profit in USD',
                range: [profitMin - profitBuffer, profitMax + profitBuffer]
            },
        };
        Plotly.newPlot(chartRef1.current, chartData, layout);
    }
  };

  useEffect(() => {
    const runFn = async () => {
        const token = await getAccessTokenSilently();
        const isAllowed = await api.IsOperationAllowed(token);
        if (isAllowed === undefined) {
          validUser = 1
          setSubmitMsg('something')
          setSuccessModalOpen(true);
          return
        } else if (isAllowed.status === 'error') {
          if (isAllowed.errorCode === 1007) {
            validUser = 1
            setSubmitMsg('nouser')
            setSuccessModalOpen(true);
          } else if (isAllowed.errorCode === 1009) {
            validUser = 1
            setSubmitMsg('noauth')
            setSuccessModalOpen(true);
          } else {
            validUser = 1
            setSubmitMsg('fetchfailed')
            setSuccessModalOpen(true);
          }
          // console.log("Operation Allowed", isAllowed);
          return
        } else {
          validUser = 0
          setSubmitMsg('ok')
          setSuccessModalOpen(true);
        }
        const pMin = constants.MINPOWER / 1000000.
        setUseFamilyPowerMin(pMin.toFixed(4))
        const pMax = constants.AT1500_MAXPOWER / 1000000.
        setUseFamilyPowerMax(pMax.toFixed(4))
        const deviceGroups = await api.GetDGNames(token);
        if (deviceGroups.status === 'error') {
            if (deviceGroups.errorCode === 1007) {
                validUser = 1
                setSubmitMsg('nouser')
                setSuccessModalOpen(true);
            } else {
                validUser = 1
                setSubmitMsg('fetchfailed')
                setSuccessModalOpen(true);
            }
        } else {
          const groupList = Object.keys(deviceGroups)
          var minerGroupList = [];
          for (let i=0; i < groupList.length; i++) {
              minerGroupList.push(groupList[i]);
          }
          minerGroupList.sort();
          if (minerGroup !== '' && minerGroupList.includes(minerGroup) === false) {
            setMinerGroup(minerGroupList[0])
            localStorage.setItem(DASHBOARD_V2_GROUP, minerGroupList[0]);
          }
          setMinerGroups(minerGroupList)
          validUser = 0
        }
        const savedTabValue = localStorage.getItem(DASHBOARD_V2_TAB);
        if (savedTabValue) {
          setTabValue(parseInt(savedTabValue));
        }
    }
    runFn();
  }, [getAccessTokenSilently]);

 //Fetch dgInfo when the component mounts
  useEffect(() => {
    const fetchEffect = async () => {
      //console.log('in useeffect and fetchDGInfo', minerGroup)
      await fetchDgInfo(true, minerGroup);
    }
    fetchEffect();
  }, [minerGroup]);

  useEffect(() => {
    if (dgInfo && calendarRef.current && calendarRef.current.refresh) {
      calendarRef.current.refresh();
    }
  }, [dgInfo]);

  //Render the chart when inputIsPerGroup or dgInfo changes
  useEffect(() => {
    renderChart();
    renderChart1();
  }, [dgInfo, value]);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      //console.log("Refresh the dashboard", minerGroup)
      fetchDgInfo(false, minerGroup);
      renderChart();
      renderChart1();
    }, 60000);
    return () => clearInterval(refreshInterval);
  }, [minerGroup]); // Empty dependency array to run only on component mount


  const handleCalendarSubmit = () => {
    // Call the submit handler method from the Calendar component
    //console.log('handleCalendarSubmit',typeof startTimeString)

    const sDate = new Date(startTimeString);
    const sDay = sDate.getDate();
    const sMonth = sDate.getMonth() + 1; // Month is zero-based, so add 1 to get the correct month
    const sYear = sDate.getFullYear();
    const startDay = `${sDay}/${sMonth}/${sYear}`;

    if (endTimeString === '') {
      const startTime = new Date(startTimeString);
      startTime.setHours(23, 59, 59, 999);
      const endTime = new Date(startTime);
      // const endTime = new Date(startTime);
      // endTime.setHours(startTime.getHours() + 23, 59, 59, 999);
      //console.log("End Time:", endTime);
      setEndTimeString(endTime)
      setEndTimeValue(dayjs(endTime));
      const eDate = new Date(endTime);
      const eDay = eDate.getDate();
      const eMonth = eDate.getMonth() + 1; // Month is zero-based, so add 1 to get the correct month
      const eYear = eDate.getFullYear();
      const endDay = `${eDay}/${eMonth}/${eYear}`;
      var powerValue = changePowerValue
      if (calendarRef.current) {
        calendarRef.current.addNew();
        let localtitle = "Start: " + startTimeString + "  End: " + endTime + "  Power: " + powerValue + "mW"
        //console.log('localTitle', localtitle)
        let message, addSuccessCheck = calendarRef.current.subHandler(localtitle, powerValue, startTimeString, endTime, startDay, endDay, 'blue', 'manual');
        //console.log('addSuccessCheck', addSuccessCheck)
        return message, addSuccessCheck;
      }
      return "no calendar", false;
    } else {
      const eDate = new Date(endTimeString);
      const eDay = eDate.getDate();
      const eMonth = eDate.getMonth() + 1; // Month is zero-based, so add 1 to get the correct month
      const eYear = eDate.getFullYear();
      const endDay = `${eDay}/${eMonth}/${eYear}`;
      var powerValue = changePowerValue
      if (calendarRef.current) {
        calendarRef.current.addNew();
        let localtitle = "Start: " + startTimeString + "  End: " + endTimeString + "  Power: " + powerValue + "mW"
        //console.log('localTitle', localtitle)
        let message, addSuccessCheck = calendarRef.current.subHandler(localtitle, powerValue, startTimeString, endTimeString, startDay, endDay, 'blue', 'manual');
        //console.log('addSuccessCheck', addSuccessCheck)
        return message, addSuccessCheck;
      }
      return "no calendar", false;
    }
  };


  const handleCancelConfirmation = () => {
    setShowPopup(false);
  }

  const handleConfirm = () => {
    setShowPopup(false);
    setScheduleMode(true);
  }

  const handleinputDesiredJTHS = (event) => {
    const value = event.target.value;
    if (value.trim() !== "" && value >= 0) {
      setinputDesiredJTHS(value.trim());
      setinputDesiredJTHSError({ error: false, helperText: "" });
    } else {
      if (value >= 0) setinputDesiredJTHS(value.trim());
      setinputDesiredJTHSError({ error: true, helperText: "" });
    }
  };

  const handleinputPreviewHashPrice = (event) => {
    const value = event.target.value;
    if (value.trim() !== '' && value >= 0) {
      setinputPreviewHashPrice(value.trim());
      setDisplayHashPrice(value.trim());
      const tmpRevenue = displayHashRate * parseFloat(value)
      const tmpPowerCost = displayPowerUsage * displayEnergyPrice * 24
      const tmpProfit = tmpRevenue - tmpPowerCost
      setRevenue(tmpRevenue.toFixed(2))
      if (tmpPowerCost === 0) {
        setPowerCost('0.00')
      } else {
        setPowerCost(tmpPowerCost.toFixed(2))
      }
      setProfit(tmpProfit.toFixed(2))
      setinputPreviewHashPriceError({ error: false, helperText: "" });
    } else {
      if (value >= 0) setinputPreviewHashPrice(value.trim());
      setDisplayHashPrice(currentHashPrice);
      const tmpRevenue = displayHashRate * currentHashPrice
      const tmpPowerCost = displayPowerUsage * displayEnergyPrice * 24
      const tmpProfit = tmpRevenue - tmpPowerCost
      setRevenue(tmpRevenue.toFixed(2))
      if (tmpPowerCost === 0) {
        setPowerCost('0.00')
      } else {
        setPowerCost(tmpPowerCost.toFixed(2))
      }
      setProfit(tmpProfit.toFixed(2))
      setinputPreviewHashPriceError({ error: true, helperText: "" });
    }
  };

  const submitHandler = async() => {
    const token = await getAccessTokenSilently();
    const info = {}
    // //console.log('submitHandler', minerGroup, inputDesiredJTHS )
    setIsLoading(true);
    if (minerGroup === '') {
      setSubmitMsg("minergroup");
      setSuccessModalOpen(true);
      setIsLoading(false);
      return;
    }

    if (inputDesiredJTHS === '') {
      setSubmitMsg("invalidjths");
      setSuccessModalOpen(true);
      setIsLoading(false);
      return;
    }
    info.dgname = minerGroup
    info.jths = parseFloat(inputDesiredJTHS)
    info.mode = 'manual'

    const tuneResp = await api.PostManualTune(token, info);
    if (tuneResp === undefined || !tuneResp.hasOwnProperty('status')) {
      setSubmitMsg("manualtuneunknown");
    } else if (tuneResp.status === 'ok') {
      setSubmitMsg("manualtuneok")
      setmanualTuneMsg(tuneResp.msg)
    } else if (tuneResp.status === 'error') {
      setSubmitMsg("manualtuneerror")
      setmanualTuneMsg(tuneResp.msg)
    } else {
      setSubmitMsg("manualtuneunknown")
    }
    setSuccessModalOpen(true);
    setIsLoading(false);
  }

  const submitHandlerDR = async() => {
    const token = await getAccessTokenSilently();
    const info = {}
    setIsLoading(true);
    /*
     * Check the input validity
     */
    if (minerGroup === '') {
      setSubmitMsg("minergroup");
      setSuccessModalOpen(true);
      setIsLoading(false);
      return;
    }
    if (changePowerValue === '' && !standbyMode) {
      setSubmitMsg("invalidpower");
      setSuccessModalOpen(true);
      setIsLoading(false);
      return;
    }
    if ((scheduleMode || standbyMode) && changeRampUpDownTime === '') {
      setSubmitMsg("invalidrampupdown");
      setSuccessModalOpen(true);
      setIsLoading(false);
      return;
    }
    //console.log("submitHandlerDR start ", startTimeString, " endtime ", endTimeString)
    if ((scheduleMode || standbyMode) && startTimeString === '') {
      setSubmitMsg("invalidstarttime");
      setSuccessModalOpen(true);
      setIsLoading(false);
      return;
    }
    /*
     * In case of schedule mode is set, validate the start and end time against current time
     */
    if (scheduleMode || standbyMode) {
      let timelimit = 9;
      if (standbyMode) {
        timelimit = 1;
      }
      const currentTime = new Date();
      const startTime = new Date(startTimeString);
      if ((startTime <= currentTime) || (startTime.getTime() - currentTime.getTime()) < (timelimit * 60 * 1000)) {
        if (timelimit == 9) {
          setSubmitMsg("invalidtime10");
        } else {
          setSubmitMsg("invalidtime5");
        }
        setSuccessModalOpen(true);
        setIsLoading(false);
        return;
      }
      if (endTimeString === '') {
        const startTime = new Date(startTimeString);
        const endTime = new Date(startTime);
        startTime.setHours(23, 59, 59, 999);
        // const endTime = new Date(startTime);
        // endTime.setHours(startTime.getHours() + 23, 59, 59, 999);
        //console.log("End Time:", endTime);
        setEndTimeString(endTime)
        setEndTimeValue(dayjs(endTime));

        //console.log('endTimeString ', endTimeString, ' endtime ', endTime)
        if ((endTime <= startTime ||
          (endTime.getTime() - startTime.getTime()) < (timelimit+1 * 60 * 1000))) {
          if (timelimit == 9) {
            setSubmitMsg("invalidtime");
          } else {
            setSubmitMsg("invalidtimestandby");
          }
          setSuccessModalOpen(true);
          setIsLoading(false);
          return;
        }
        const endTimeinUTC = endTime.toISOString();
        const startTimeinUTC = startTime.toISOString();

        const timestampPattern = /^\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[-+]\d{4} \(.*\)$/;
        if (timestampPattern.test(startTimeString)) {
            info.start_time = await timeFormat(startTimeinUTC)
        } else {
          setSubmitMsg("invalidtimeformat");
          setSuccessModalOpen(true);
          setIsLoading(false);
          return;
        }
        if (timestampPattern.test(endTime)) {
          info.end_time = await timeFormat(endTimeinUTC)
        } else {
          setSubmitMsg("invalidtimeformat");
          setSuccessModalOpen(true);
          setIsLoading(false);
          return;
        }
      } else {
        const endTime = new Date(endTimeString);
        if ((endTime <= startTime ||
            (endTime.getTime() - startTime.getTime()) < (timelimit+1 * 60 * 1000))) {
          if (timelimit == 9) {
            setSubmitMsg("invalidtime");
          } else {
            setSubmitMsg("invalidtimestandby");
          }
          setSuccessModalOpen(true);
          setIsLoading(false);
          return;
        }
        const endTimeinUTC = endTime.toISOString();
        const startTimeinUTC = startTime.toISOString();

        const timestampPattern = /^\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[-+]\d{4} \(.*\)$/;
        if (timestampPattern.test(startTimeString)) {
            info.start_time = await timeFormat(startTimeinUTC)
        } else {
          setSubmitMsg("invalidtimeformat");
          setSuccessModalOpen(true);
          setIsLoading(false);
          return;
        }
        if (timestampPattern.test(endTimeString)) {
          info.end_time = await timeFormat(endTimeinUTC)
        } else {
          setSubmitMsg("invalidtimeformat");
          setSuccessModalOpen(true);
          setIsLoading(false);
          return;
        }
      }
    }
    info.dgname = minerGroup
    if (standbyMode) {
      info.target_power_usage = 0.00001 //in MW
    } else {
      info.target_power_usage = parseFloat(changePowerValue)
    }
    info.operation = 'now'
    let op = 'manualPowerCurtailNow'
    if (scheduleMode || standbyMode) {
      let message, check = handleCalendarSubmit();
      if (!check) {
        alert(alertMessage)
        setIsLoading(false);
        return
      }
      info.source = 'manual'
      info.operation = 'schedule'
      info.scheduleOP = 'add'
      info.rampup_time = 600
      if (changeRampUpDownTime !== '') {
        info.rampup_time = parseInt(changeRampUpDownTime)
        if (info.rampup_time < 120 || info.rampup_time > 86400) {
          setSubmitMsg("invalidrampupdown");
          setSuccessModalOpen(true);
          setIsLoading(false);
          return
        }
      } else {
        setSubmitMsg("invalidrampupdown");
        setSuccessModalOpen(true);
        setIsLoading(false);
        return
      }
      info.id = ''
      /* Set the operation to save the schedule in the database */
      op = 'powerCurtailSchedule'
      const postConfig = await api.PostPowerCurtail(token, info, op);
      if (postConfig === undefined || !postConfig.hasOwnProperty('status')) {
        setSubmitMsg("unknown");
        setSuccessModalOpen(true);
        setIsLoading(false);
        return
      } else if (postConfig.status === 'ok') {
        if (postConfig.hasOwnProperty('id') && postConfig.id !== undefined && postConfig.id !== null && postConfig.id !== '') {
          info.id = postConfig.id
          await fetchDgInfo(false, minerGroup);
        } else {
          setSubmitMsg("error");
          setmanualTuneMsg("Failed to create the power curtail schedule Configuration")
          setSuccessModalOpen(true);
          setIsLoading(false);
          return
        }
        /* Set the operation to send the schedule to FluxOS */
        op = 'manualPowerCurtailSchedule'
        //console.log("Successfully saved the power curtail schedule Configuration")
      } else {
        setSubmitMsg("error");
        setmanualTuneMsg(postConfig.msg)
        setSuccessModalOpen(true);
        setIsLoading(false);
        return
      }
    }
    const pcResp = await api.PostPowerCurtail(token, info, op);
    if (pcResp === undefined || !pcResp.hasOwnProperty('status')) {
      setSubmitMsg("manualtuneunknown");
    } else if (pcResp.status === 'ok') {
      setSubmitMsg("manualtuneok")
      setmanualTuneMsg(pcResp.msg)
    } else if (pcResp.status === 'error') {
      setSubmitMsg("manualtuneerror")
      setmanualTuneMsg(pcResp.msg)
    } else {
      setSubmitMsg("manualtuneunknown")
    }
    setSuccessModalOpen(true);
    setIsLoading(false);
  }

  const handleCancelConfirmationDR = () => {
    setShowPopup2(false);
  };

  const handleConfirmation = () => {
    submitHandler();
    setShowPopup(false);
  };

  const handleConfirmationDR = () => {
    submitHandlerDR();
    setShowPopup2(false);
  };


  const clearHandler = async() => {
    setinputDesiredJTHS('')
  }

  const clearHandlerDR = async() => {
    let starttimevalue = 10;
    let endtimevalue = 20;
    if (standbyMode) {
      starttimevalue = 2;
      endtimevalue = 60;
    }
    setChangePowerValue('')

    let startValue = dayjs().add(starttimevalue, 'minute')
    let endValue = dayjs().add(endtimevalue, 'minute')

    setStartTimeValue(startValue)
    setEndTimeValue(endValue)

    let starttime = startValue['$d'].toString()
    if (starttime !== '' && starttime !== undefined && starttime !== null & starttime !== 'Invalid Date') {
      setStartTimeString(starttime)
    } else {
      //console.log('wrong place')
      setStartTimeString('')
    }
    let endtime = endValue['$d'].toString()
    if (endtime !== '' && endtime !== undefined && endtime !== null & endtime !== 'Invalid Date') {
      setEndTimeString(endtime)
    } else {
      setEndTimeString('')
    }
    setChangeRampUpDownTime('')
  }

  const resetHandlerDR = async() => {
    let starttimevalue = 10;
    let endtimevalue = 20;
    if (standbyMode) {
      starttimevalue = 2;
      endtimevalue = 60;
    }
    let startValue = dayjs().add(starttimevalue, 'minute')
    let endValue = dayjs().add(endtimevalue, 'minute')

    setStartTimeValue(startValue)
    setEndTimeValue(endValue)

    let starttime = startValue['$d'].toString()
    if (starttime !== '' && starttime !== undefined && starttime !== null & starttime !== 'Invalid Date') {
      setStartTimeString(starttime)
    } else {
      //console.log('wrong place')
      setStartTimeString('')
    }
    let endtime = endValue['$d'].toString()
    if (endtime !== '' && endtime !== undefined && endtime !== null & endtime !== 'Invalid Date') {
      setEndTimeString(endtime)
    } else {
      setEndTimeString('')
    }
  }

  const handleMinerGroup = async(value) => {
    setMinerGroup(value);
    localStorage.setItem(DASHBOARD_V2_GROUP, value);
    setChangePowerValue('')
  }

  const [manualTuneMsg, setmanualTuneMsg]=useState('');


  if (validUser === 0) {
    return (
      <PageContainer title="Power Management" description="Power Management">
        <Typography variant="caption" style={{ fontStyle: "italic" }}>
          <strong>NOTE: </strong>This operation requires miner firmware 2024-06.06 or later
        </Typography>
        <br/><br/>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleTabChange} aria-label="Profile">
            <Tab sx = {{textTransform: "none", width:'300px'}} label="Price Response" {...a11yProps(0)} />
            <Tab sx = {{textTransform: "none", width:'300px'}} label="Demand Response" {...a11yProps(1)} />
          </Tabs>
        </Box>
      <TabPanel value={value} index={0} disabled={!isTabEnabled}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px', textAlign: 'left' }}>
        <span style={{ marginLeft: '5px', marginRight: '10px' }}>Select a Miner Group:</span>
              <Autocomplete
                disablePortal
                onChange={(event, value) => handleMinerGroup(value)}
                sx = {{
                width: '200px',
                }}
                disableClearable
                id="mode"
                value = {minerGroup}
                options={minerGroups}
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                <li {...props}>
                      {option}
                </li>
                )}
                getOptionSelected={(option, value) => option === value}
                renderInput={(params) => (
                <TextField {...params} size="small" placeholder="Miner Group" aria-label="Select mode" />
                )}
              />
          </div>
        <Box sx={{ mt: 2}}>
            <PaperWrap>
              <Box sx={{ display: 'flex' }}>
              <Typography variant="h7" component="div" sx={{ mr: '5px' }}>Current Hash Price (PH/s) {currentHashPrice} USD</Typography>
              </Box>
              {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h7" component="div" sx={{ mt: '20px' }}>
                  Preview Tuning With Hash Price
              </Typography>
              <TextField
                  type="text"
                  label="$/Ph/s/day"
                  sx={{
                  mt: '20px',
                  ml: '10px',
                  backgroundColor: '#f5f5f5',
                  }}
                  value={inputPreviewHashPrice}
                  onChange={handleinputPreviewHashPrice}
                  margin="dense"
                  error={inputPreviewHashPriceError.error}
                  helperText={inputPreviewHashPriceError.helperText}
              />
              </Box> */}
            </PaperWrap>
        </Box>
          <br/>
          <br/>
          <div style={{ display: 'flex', 'alignitems': 'flex-start', width: 1300}}>
            {minerGroup !== '' && (displayISO === 'ERCOT' || displayISO === 'NONE') && (
            <div id="chartContainer" ref={chartRef} style={{ width: 1500,}}/>
            )}
            {minerGroup !== '' && displayPPAType === 'Hedge' && displayISO === 'ERCOT' && (
            <div id="chartContainer1" ref={chartRef1} style={{ width: 1500,}}/>
            )}
            <Box sx={{ mt: 2, mr: -80}}>
              <PaperWrap>
                <Box sx={{ display: 'flex', mt: '20px', width: '800px'}}>
                <Typography variant="h7" component="div" >PPA Type = <strong>{displayPPAType}</strong> ISO = <strong>{displayISO}</strong></Typography>
                {displayPPAType === "Index" ? (<Typography variant="h7" component="div" sx={{ml: 5}} > Zone = <strong>{displayZone}</strong></Typography>) : null}
                </Box>
                <Box sx={{ display: 'flex', mt: '20px', width: '800px'}}>
                {/* <Typography variant="h7" component="div" >Total Miners = <strong>{displayTotalDevices}</strong></Typography> */}
                <Typography variant="h7" component="div">Total active miners hashing in the last hour = <strong>{displayTotalActiveDevices}</strong> out of <strong>{displayTotalDevices}</strong> Miners&nbsp;&nbsp;</Typography>
                {Object.entries(displayModelCount).map(([key, value]) => (
                  <Typography key={key} variant="h7" component="div">
                    <strong>{key}:</strong> {value} &nbsp;&nbsp;
                  </Typography>
                ))}
                </Box>
                <Box sx={{ display: 'flex', mt: '20px', width: '800px'}}>
                <Typography variant="h7" component="div" >Revenue = Aggr. Hash Rate in PHs <strong>{displayHashRate}</strong> x Hash Price per PHs/day <strong>{displayHashPrice}</strong> = <strong>{revenue}</strong></Typography>
                <FractionalUnitDisplay num={"$"} den={"day"}/>
                </Box>
                <Box sx={{ display: 'flex', mt: '20px', width: '800px'}}>
                <Typography variant="h7" component="div" >Power Cost = Aggr. Power Usage <strong>{displayPowerUsage}</strong> in MW x Power Price <strong>{displayEnergyPrice}</strong> per MW/24hr = <strong>{powercost}</strong></Typography>
                <FractionalUnitDisplay num={"$"} den={"day"}/>
                </Box>
                <Box sx={{ display: 'flex', mt: '20px', width: '800px'}}>
                <Typography variant="h7" component="div">Estimated Profit Per Day = Revenue <strong>{revenue}</strong> - Power Cost <strong>{powercost}</strong> = <strong>{profit}</strong></Typography>
                <FractionalUnitDisplay num={"$"} den={"day"}/>
                </Box>
              </PaperWrap>
            </Box>
          </div>
              {/* <div style={{ display: 'flex', justifyContent: 'left' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="220" height="20">
                  <text x="10" y="15" fill={'blue'} fontSize="12">
                  <line x1="10" y1="10" x2="210" y2="10" stroke={'blue'} strokeDasharray="5,5" />
                    ----
                  <tspan fill='black' fontSize="10"> Power Price</tspan>
                </text>
              </svg>
              </div>
              <div style={{ display: 'flex', justifyContent: 'left' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="220" height="20">
                  <text x="10" y="15" fill={'green'} fontSize="12">
                  <line x1="10" y1="10" x2="210" y2="10" stroke={'green'} strokeDasharray="5,5" />
                    ----
                  <tspan fill='black' fontSize="10">Efficiency</tspan>
                </text>
              </svg>
              </div> */}
          <Box sx={{ mt: 2}}>
            <PaperWrap>
              <Typography variant="h6" component="div" sx={{mt: '20px', width: '100px'}} >Tune</Typography>
              <Box sx={{ display: 'flex'}}>
                <Typography variant="h7" component="div" sx={{mt: '20px', width: '3000px'}} >Current Aggregated Efficiency <strong>{displayJTHs}</strong> J/THs</Typography>
              </Box>
              <br/>
              <Box sx={{ display: 'flex'}}>
                <Typography variant="h7" component="div" sx={{mt: '20px', mr: '5px'}} >Target Efficiency</Typography>
                <TextField
                    type="text"
                    label="J/THs"
                    sx={{
                      mt: '10px',
                      ml: '10px',
                      backgroundColor: '#f5f5f5',
                    }}
                    value={inputDesiredJTHS}
                    onChange={handleinputDesiredJTHS}
                    margin="dense"
                    error={inputDesiredJTHSError.error}
                    helperText={inputDesiredJTHSError.helperText}
                />
              </Box>
              <Typography variant="body2" style={{ color: 'gray', fontWeight: 'bolder'}} >Note: </Typography>
              <Typography variant="body2">
                This operation would turn off the <strong>AutoTune</strong> feature for{" "}
                <strong>{minerGroup === "Teraflux Group" ? "Default Group" : minerGroup}</strong> group
              </Typography>
            </PaperWrap>
          </Box>
          <Box sx={{ml: 1, mr: 5, mt: 4}}>
            <Button onClick={clearHandler} type="submit" variant="contained" sx={{ mr: 3 }}>
              Clear
            </Button>
              <Button
                    onClick={() => {
                      setShowPopup(true);
                    }}
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                >
                    {isLoading ? 'In Progress...' : 'Submit'}
              </Button>
                {showPopup && (
                    <div className="popup">
                      <div className="popup-content">
                        <div className="close-icon" onClick={() => setShowPopup(false)}>
                        <FaTimes />
                        </div>
                        <p>Are you sure you want to proceed with this operation?</p>
                        <div className="button-container">
                          <button onClick={handleCancelConfirmation}>No</button>
                          <button onClick={handleConfirmation}>Yes</button>
                        </div>
                      </div>
                    </div>
                )}
              <div>
                <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg} ManualTuneMsg={manualTuneMsg}/>
              </div>
          </Box>
      </TabPanel>
      <br/>
      <div style={{ textAlign: 'center' }}>
        <br/>
        <TabPanel value={value} index={1} disabled={!isTabEnabled}>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px', textAlign: 'left' }}>
              <span style={{ marginLeft: '5px', marginRight: '10px' }}>Select a Miner Group:</span>
                <Autocomplete
                  disablePortal
                  onChange={(event, value) => handleMinerGroup(value)}
                  sx = {{
                  width: '200px',
                  }}
                  disableClearable
                  id="mode"
                  value = {minerGroup}
                  options={minerGroups}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                  <li {...props}>
                        {option}
                  </li>
                  )}
                  getOptionSelected={(option, value) => option === value}
                  renderInput={(params) => (
                  <TextField {...params} size="small" placeholder="Miner Group" aria-label="Select mode" />
                  )}
                />
                {/* <Typography variant="h7" component="div" sx={{ml: '30px', width: '400px'}} >Current group power usage: {displayPowerUsage} MW</Typography> */}
            </div>
            <br/>
            <Box sx={{ mt: 2, mr: -80}}>
                <PaperWrap>
                  <Box sx={{ display: 'flex', mt: '20px', width: '800px'}}>
                  <Typography variant="h7" component="div" >PPA Type = <strong>{displayPPAType}</strong> ISO = <strong>{displayISO}</strong></Typography>
                  {displayPPAType === "Index" ? (<Typography variant="h7" component="div" sx={{ml: 5}} > Zone = <strong>{displayZone}</strong></Typography>) : null}
                  </Box>
                  <Box sx={{ display: 'flex', mt: '20px', width: '800px'}}>
                  <Typography variant="h7" component="div">Total active miners hashing in the last hour = <strong>{displayTotalActiveDevices}</strong> out of <strong>{displayTotalDevices}</strong> Miners&nbsp;&nbsp;</Typography>
                  {Object.entries(displayModelCount).map(([key, value]) => (
                    <Typography key={key} variant="h7" component="div">
                      <strong>{key}:</strong> {value} &nbsp;&nbsp;
                    </Typography>
                  ))}
                  </Box>
                  <Box sx={{ display: 'flex', mt: '20px', width: '800px'}}>
                  <Typography variant="h7" component="div" >Revenue = Aggr. Hash Rate in PHs <strong>{displayHashRate}</strong> x Hash Price per PHs/day <strong>{displayHashPrice}</strong> = <strong>{revenue}</strong></Typography>
                  <FractionalUnitDisplay num={"$"} den={"day"}/>
                  </Box>
                  <Box sx={{ display: 'flex', mt: '20px', width: '800px'}}>
                  <Typography variant="h7" component="div" >Power Cost = Aggr. Power Usage <strong>{displayPowerUsage}</strong> in MW x Power Price <strong>{displayEnergyPrice}</strong> per MW/24hr = <strong>{powercost}</strong></Typography>
                  <FractionalUnitDisplay num={"$"} den={"day"}/>
                  </Box>
                  <Box sx={{ display: 'flex', mt: '20px', width: '800px'}}>
                  <Typography variant="h7" component="div">Estimated Profit Per Day = Revenue <strong>{revenue}</strong> - Power Cost <strong>{powercost}</strong> = <strong>{profit}</strong></Typography>
                  <FractionalUnitDisplay num={"$"} den={"day"}/>
                  </Box>
                </PaperWrap>
            </Box>
            <br/>
            <Box sx={{ mt: 2 }}>
              <Box sx={{ mt: '20px', display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" component="div" sx={{ ml: -3, width: '250px' }}>
                  Power Curtail Operation
                </Typography>
                <Typography variant="body1" component="div" sx={{ mr: 1 }}>
                  Now
                </Typography>
                <Checkbox
                  checked={!scheduleMode && !standbyMode}
                  onChange={() => {
                    setNowMode(true);
                    setScheduleMode(false);
                    setStandbyMode(false);
                    localStorage.setItem(CURTAILNOW_MODE, true)
                    localStorage.setItem(CURTAIL_MODE, false)
                    localStorage.setItem(STANDBY_MODE, false)
                  }}
                  sx={{ p: 0 }}
                />
                <Typography variant="body1" component="div" sx={{ ml: 2, mr: 1 }}>
                  Schedule
                </Typography>
                <Checkbox
                  checked={scheduleMode}
                  onChange={() => {
                    setScheduleMode(true);
                    setStandbyMode(false);
                    setNowMode(false);
                    localStorage.setItem(CURTAIL_MODE, true)
                    localStorage.setItem(STANDBY_MODE, false)
                    localStorage.setItem(CURTAILNOW_MODE, false)
                  }}
                  sx={{ p: 0 }}
                />
                <Typography variant="body1" component="div" sx={{ ml: 2, mr: 1 }}>
                  Standby
                </Typography>
                <Checkbox
                  checked={standbyMode}
                  onChange={() => {
                    setStandbyMode(true);
                    setScheduleMode(false);
                    setNowMode(false);
                    localStorage.setItem(STANDBY_MODE, true)
                    localStorage.setItem(CURTAIL_MODE, false)
                    localStorage.setItem(CURTAILNOW_MODE, false)
                  }}
                  sx={{ p: 0 }}
                />
              </Box>
              {showPopup && (
                <div className="popup">
                  <div className="popup-content">
                    <p>By selecting schedule, you will be switching Demand Config Tuning for this Miner Group from Auto to Manual. Are you sure you want to do this?</p>
                    <div className="button-container">
                      <button onClick={handleCancelConfirmation}>No</button>
                      <button onClick={handleConfirm}>Yes</button>
                    </div>
                  </div>
                </div>
              )}
              <PaperWrap>
                {!standbyMode && (
                <Box sx={{ display: 'flex', textAlign: 'left', alignItems: 'center' }}>
                  <Typography variant="body1" component="div" sx={{ mt: 2, width: '150px' }}>
                    Change Power
                  </Typography>
                  <TextField
                    type="text"
                    label="in MW"
                    value={changePowerValue}
                    onChange={(event) => handleChangePowerValue(event)}
                    sx={{ mt: '10px', backgroundColor: '#f5f5f5' }}
                    margin="dense"
                    error={changePowerValueError.error}
                    helperText={changePowerValueError.helperText}
                  />
                  <Typography variant="body2" style={{ color: 'gray', marginTop: '20px', marginLeft: '10px' }}>
                    Suggested MW value between <strong>{displayPowerMin}</strong> and <strong>{displayPowerMax}</strong>
                  </Typography>
                </Box>
                )}
                {(scheduleMode || standbyMode) && (
                  <>
                    <Box sx={{ display: 'flex', mt: 2, textAlign: 'left', alignItems: 'center' }}>
                      <Typography variant="body1" component="div" sx={{ width: '150px' }}>
                        Start Time
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="Start Time"
                          value={startTimeValue}
                          onChange={(newValue) => handleStartTimeValue(newValue)}
                          format="YYYY-MM-DD hh:mm a"
                        />
                      </LocalizationProvider>
                    </Box>

                    <Box sx={{ display: 'flex', mt: 2, textAlign: 'left', alignItems: 'center' }}>
                      <Typography variant="body1" component="div" sx={{ width: '150px' }}>
                        End Time
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="End Time"
                          value={endTimeValue}
                          onChange={(newValue) => handleEndTimeValue(newValue)}
                          format="YYYY-MM-DD hh:mm a"
                        />
                      </LocalizationProvider>
                    </Box>

                    <Box sx={{ display: 'flex', mt: 2, textAlign: 'left', alignItems: 'center' }}>
                      <Typography variant="body1" component="div" sx={{ width: '150px' }}>
                        Ramp Up/Down Time
                      </Typography>
                      <TextField
                        type="text"
                        label="in seconds b/w 120 and 86400"
                        value={changeRampUpDownTime}
                        onChange={(event) => handleChangeRampUpDownTimeValue(event)}
                        sx={{ mt: '10px', backgroundColor: '#f5f5f5' }}
                        margin="dense"
                        error={changeRampUpDownValueError.error}
                        helperText={changeRampUpDownValueError.helperText}
                      />
                    </Box>
                  </>
                )}

                <Box sx={{ mt: 2, textAlign: 'left' }}>
                  <Button onClick={clearHandlerDR} variant="contained" sx={{ mr: 2 }}>
                    Clear All
                  </Button>
                  {(scheduleMode || standbyMode) && (
                    <Button onClick={resetHandlerDR} variant="contained" sx={{ mr: 2 }}>
                      Clear Schedule
                    </Button>
                  )}
                  <Button
                    onClick={() => setShowPopup2(true)}
                    disabled={isLoading}
                    variant="contained"
                  >
                    {isLoading && (scheduleMode || standbyMode) ? 'In Progress...' : (scheduleMode || standbyMode) ? 'Save & Submit' : 'Submit'}
                  </Button>

                  {showPopup2 && (
                    <div className="popup">
                      <div className="popup-content">
                        <div className="close-icon" onClick={() => setShowPopup(false)}>
                          <FaTimes />
                        </div>
                        <p>Are you sure you want to proceed with this operation?</p>
                        <div className="button-container">
                          <button onClick={handleCancelConfirmationDR}>No</button>
                          <button onClick={handleConfirmationDR}>Yes</button>
                        </div>
                      </div>
                    </div>
                  )}
                <div>
                  <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg} ManualTuneMsg={manualTuneMsg}/>
                </div>
            </Box>
              </PaperWrap>
              <Box sx={{ mt: 2, textAlign: 'left', ml:1 }}>
                {(scheduleMode || standbyMode) && validUser === 0 &&
                <ACalendar forwardedRef={calendarRef} dgInfoMinerGroup={dgInfo} groupName={minerGroup} onEvent={handleCalendarEvent}/>}
              </Box>
            </Box>
            <div>
            </div>
        </TabPanel>
      </div>
      <Footer />
      </PageContainer>
    );
  } else if (validUser !== -1 && validUser !== 0) {
    return(
      <PageContainer title="Power Management" description="this is Calendar page">
    <div>
    <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg}/>
    </div>
    </PageContainer>);
  } else {
    return <PageContainer title="Power Management" description="this is Calendar page"><PageLoading /></PageContainer>;
  }
};

export default withAuthenticationRequired(DashboardV2, {
  onRedirecting: () => <Loading />,
});
