import React, { useState, useEffect } from "react";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Command from "./command";
import PaperWrap from "../components/paper-wrap";
import Footer from "../components/footer";
import PageContainer from "../components/PageContainer";
import Autocomplete from "@mui/material/Autocomplete";
import * as api from "../api/api";
import Modal from "react-modal";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import CloseIcon from "@mui/icons-material/Close";
import { FaTimes } from "react-icons/fa";
import "../device/dgstyles.css";
import Button from "@mui/material/Button";
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import * as constants from "../constants";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import CircularProgress from "@mui/material/CircularProgress";
import PageLoading from "../components/pageloading";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const PRICE_GROUP_NAME = "pcGroupName";
const PRICE_GROUP_LIST = "pcGroupList";
const PRICE_UPDATED_GROUPS = "pcUpdatedGroups";
const PRICE_APPLY_GROUPS = "pcApplyGroups";
const PRICE_SELECT_ALL = "pcSelectAll";

const SuccessModal = ({ isOpen, onClose, Status, ManualTuneMsg }) => {
  let h4Msg = "";
  let pMsg = "";
  switch (Status) {
    case "ok":
      h4Msg = "Success Message";
      pMsg = "Successfully submitted the configuration";
      break;
    case "priceok":
      h4Msg = "Success Message";
      pMsg = ManualTuneMsg;
      break;
    case "invalidrange":
      h4Msg = "Failure Message";
      pMsg = "Please fill up with valid range values";
      break;
    case "unknown":
      h4Msg = "Failure Message";
      pMsg = "Failed to perform the operation";
      break;
    case "invalidpricezone":
      h4Msg = "Invalid Input";
      pMsg = "Please select the Price Zone";
      break;
    case "invalidISO":
      h4Msg = "Invalid Input";
      pMsg = "Please select the ISO";
      break;
    case "invalidpriceconfig":
      h4Msg = "Invalid Input";
      pMsg = "Please provide the price config name";
      break;
    case "invalidpricedetails":
      h4Msg = "Invalid Input";
      pMsg = "Please fill up with the valid values";
      break;
    case "error":
      if (
        ManualTuneMsg === "" ||
        ManualTuneMsg === undefined ||
        ManualTuneMsg === null
      ) {
        pMsg = "Response from the server is not as expected.";
      } else {
        pMsg = ManualTuneMsg;
      }
      break;
    case "minergroups":
      h4Msg = "Failure Message";
      pMsg = "Empty Group List";
      break;
    case "fetchfailed":
      h4Msg = "Fetch Failed!";
      pMsg =
        "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine";
      break;
    case "nouser":
      h4Msg = "Authentication Failed!";
      pMsg =
        "User is not part of the Organization. Please contact your Administrator";
      break;
    case "noauth":
      h4Msg = "Authorization Failed!";
      pMsg = "User doesn't have permission to view this page";
      break;
    default:
      h4Msg = "Unknown Error";
      pMsg =
        "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine";
      break;
  }
  if (Status !== "ok") {
    return (
      <Popup className="popup1-content" open={isOpen} onClose={onClose}>
        <h4 className="popup-title">{h4Msg}</h4>
        <div className="gap"></div>
        {typeof pMsg === "object" ? (
          <div className="success-message-container">
            {Object.keys(pMsg).map((group, index) => (
              <div key={index} className="group-message">
                <span className="group-name">{group}:</span>
                <span
                  className="group-content"
                  dangerouslySetInnerHTML={{
                    __html: pMsg[group].replace(
                      /([a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12})/gi,
                      '<span class="job-id">$1</span>'
                    ),
                  }}
                ></span>
              </div>
            ))}
          </div>
        ) : (
          <p className="success-message">{pMsg}</p>
        )}
        <div className="button-container">
          <button onClick={onClose}>Close</button>
        </div>
      </Popup>
    );
  }
};

let validUser = -1;

export const PriceConfig = () => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [submitMsg, setSubmitMsg] = useState("");
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const [manualMsg, setmanualMsg] = useState("");
  const [minerGroups, setMinerGroups] = useState([]);
  const [updatedGroups, setUpdatedGroups] = useState([]);
  const [applyGroups, setApplyGroups] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [priceConfigName, setPriceConfigName] = useState("");
  const [priceConfigName1, setPriceConfigName1] = useState("");
  const [priceConfigNames, setPriceConfigNames] = useState([]);
  const [isoConfig, setIsoConfig] = useState("");
  const isoConfigOptions = ["ERCOT", "NONE"];
  const [priceZone, setPriceZone] = useState("");
  const priceZoneOptions = constants.ERCOT_PRICE_ZONES.filter(
    (zone) => zone !== "BITCOIN"
  );
  const [hashPriceMode, setHashPriceMode] = useState(false);
  const [hashPriceinPHs, sethashPriceinPHs] = useState("");
  const [ppaType, setPPAType] = useState("");
  const ppaTypeOptions = ["Index", "Hedge"];
  const ppaTypeOptions_NOISO = ["Hedge"];
  const [inputEnergyPrice, setinputEnergyPrice] = useState("");
  const [isApplyPCLoading, setApplyPCLoading] = useState(false);
  const [isDetachPCLoading, setDetachPCLoading] = useState(false);
  const [isIndexPPALoading, setIndexPPALoading] = useState(false);
  const [isHPLoading, setHPLoading] = useState(false);

  const handleGetHashPrice = async () => {
    console.log("handleGetHashPrice");
    setHPLoading(true);
    sethashPriceinPHs("");
    try {
      const token = await getAccessTokenSilently();
      const hashPrice = await api.GetHashPrice(token);
      if (!hashPrice || !hashPrice.status || hashPrice.status !== "ok") {
        console.log("Failed to get the hashPrice");
      } else {
        console.log("GetHashPrice response:", hashPrice);
        if (
          hashPrice.data !== undefined &&
          hashPrice.data !== null &&
          hashPrice.data.BITCOIN !== undefined &&
          hashPrice.data.BITCOIN !== null
        ) {
          const HashPrice = hashPrice.data.BITCOIN.hashPriceInPHs;
          sethashPriceinPHs(HashPrice);
        }
      }
    } catch (error) {
      console.error("Error while fetching hash price:", error);
      sethashPriceinPHs("");
    }
    setHPLoading(false);
  };

  const handleGetPriceConfigNames = async () => {
    console.log("handleGetPriceConfigNames");
    const token = await getAccessTokenSilently();
    const priceInfo = {
      config_type: "priceConfig",
    };
    try {
      const priceConfigNames = await api.PostAutoTuneV2Config(
        token,
        priceInfo,
        "GETALL"
      );
      if (priceConfigNames.status === "error") {
        console.error(
          "Failed to get the price config names:",
          priceConfigNames.msg
        );
        setPriceConfigNames([]);
      } else {
        const pcNames = priceConfigNames.result.reduce((PCList, item) => {
          Object.keys(item.priceConfig).forEach((name) => {
            PCList.push(name);
          });
          return PCList;
        }, []);
        console.log("priceConfigNames Handler ", pcNames);
        pcNames.sort();
        setPriceConfigNames(pcNames);
        if (
          priceConfigName1 !== "" &&
          priceConfigName1 !== undefined &&
          priceConfigName1 !== null
        ) {
          console.log("get the config for", priceConfigName1);
          await handlePriceConfigGet(priceConfigName1);
        }
      }
    } catch (error) {
      console.error("Error while fetching price config names:", error);
      setPriceConfigNames([]);
    }
  };

  const handleHashPriceMode = async () => {
    setHashPriceMode(!hashPriceMode);
  };

  const handlePriceConfigName = (event) => {
    const value = event.target.value;
    setPriceConfigName(value);
  };

  const handlePriceConfigName1 = async (value) => {
    console.log("handlePriceConfigNames1", value);
    setPriceConfigName(value);
    setPriceConfigName1(value);
    if (value !== "" && value !== undefined && value !== null) {
      await handlePriceConfigGet(value);
    }
  };

  const handleIsoConfig = (value) => {
    setIsoConfig(value);
  };

  const handlePriceZone = (value) => {
    setPriceZone(value);
  };

  const handlePPAType = async (value) => {
    setPPAType(value);
  };

  const handleSelectAll = (event) => {
    const allOptions = minerGroups;
    const selectedOptions = event.target.checked ? allOptions : [];
    console.log("handleSelectAll", selectedOptions);
    setApplyGroups(selectedOptions);
    localStorage.setItem(PRICE_APPLY_GROUPS, JSON.stringify(selectedOptions));
    setSelectAll(event.target.checked);
    localStorage.setItem(PRICE_SELECT_ALL, event.target.checked.toString());
  };

  const applyVal = (value) => {
    console.log("applyVal", value, value.length);
    setApplyGroups(value);
    localStorage.setItem(PRICE_APPLY_GROUPS, JSON.stringify(value));
    setSelectAll(value.length === updatedGroups.length);
    localStorage.setItem(
      PRICE_SELECT_ALL,
      (Array.isArray(value)
        ? value.length === updatedGroups.length
        : false
      ).toString()
    );
  };

  const { user: user0, getAccessTokenSilently } = useAuth0();

  const [showPopup, setShowPopup] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);

  const applyPriceConfigHandler = async (dglist, b_attach) => {
    if (b_attach) {
      setApplyPCLoading(true);
    } else {
      setDetachPCLoading(true);
    }
    const token = await getAccessTokenSilently();
    if (dglist.length === 0) {
      setSubmitMsg("minergroups");
      setSuccessModalOpen(true);
      if (b_attach) {
        setApplyPCLoading(false);
      } else {
        setDetachPCLoading(false);
      }
      return;
    }

    const priceInfo = {
      config_type: "priceConfig",
      config_name: priceConfigName,
      attach: b_attach,
    };
    const postConfig = await api.PostAttachAutoTuneV2Config(
      token,
      priceInfo,
      dglist
    );
    if (postConfig === undefined || !postConfig.hasOwnProperty("status")) {
      setSubmitMsg("unknown");
      setSuccessModalOpen(true);
    } else if (postConfig.status === "ok") {
      if (b_attach) {
        if (Array.isArray(postConfig.msg)) {
          setmanualMsg(postConfig.msg.join(". "));
        } else {
          setmanualMsg(postConfig.msg);
        }
        setSubmitMsg("priceok");
      } else {
        setmanualMsg("Successfully detached the configuration");
      }
      setSuccessModalOpen(true);
    } else {
      setSubmitMsg("error");
      setmanualMsg(postConfig.msg);
      setSuccessModalOpen(true);
    }
    if (b_attach) {
      setApplyPCLoading(false);
    } else {
      setDetachPCLoading(false);
    }
    setShowPopup(false);
  };

  const handlePriceConfigGet = async (priceConfigName1) => {
    const token = await getAccessTokenSilently();
    console.log("handlePriceConfigConfirmation", priceConfigName1);
    setPCLoading1(true);

    setIsoConfig("");
    setPriceZone("");
    setPPAType("");
    setHashPriceMode(false);
    sethashPriceinPHs("");
    setinputEnergyPrice("");

    const priceInfo = {
      config_type: "priceConfig",
      config_name: priceConfigName1,
    };
    if (priceConfigName1 === "") {
      setSubmitMsg("invalidpriceconfig");
      setmanualMsg("Please provide the price config name");
      setSuccessModalOpen(true);
      setPCLoading1(false);
      return;
    }
    const getConfig = await api.PostAutoTuneV2Config(token, priceInfo, "GET");
    if (getConfig === undefined || !getConfig.hasOwnProperty("status")) {
      setSubmitMsg("unknown");
      setSuccessModalOpen(true);
    } else if (getConfig.status === "ok") {
      const cfg = getConfig.result.priceConfig[priceConfigName1];
      console.log("handlePriceConfigGet", getConfig);
      if (
        getConfig.hasOwnProperty("dglist") &&
        getConfig.dglist !== undefined &&
        getConfig.dglist !== null &&
        getConfig.dglist.length > 0
      ) {
        setApplyGroups(getConfig.dglist);
      }
      setSubmitMsg("priceok");
      setmanualMsg("Successfully fetched the configuration");
      setSuccessModalOpen(true);
      setIsoConfig(cfg.ISO);
      setPriceZone(cfg.PriceZone);
      setPPAType(cfg.PPAType);
      if (cfg.TunePreference === "Auto") {
        setHashPriceMode(true);
      } else {
        setHashPriceMode(false);
      }
      sethashPriceinPHs(cfg.HashPrice);
      setinputEnergyPrice(cfg.EnergyPrice);
    } else {
      setSubmitMsg("error");
      setmanualMsg(getConfig.msg);
      setSuccessModalOpen(true);
    }
    setPCLoading1(false);
  };

  const handlePriceConfigConfirmation = async () => {
    if (
      priceConfigName === "" ||
      isoConfig === "" ||
      (isoConfig === "ERCOT" && priceZone === "") ||
      ppaType === "" ||
      (ppaType === "Hedge" && inputEnergyPrice === "")
    ) {
      setSubmitMsg("invalidpricedetails");
      setSuccessModalOpen(true);
      setShowPopup(false);
      return;
    }
    const token = await getAccessTokenSilently();
    setPCLoading(true);
    console.log("handlePriceConfigConfirmation", priceConfigName);
    const priceInfo = {
      config_type: "priceConfig",
      config_name: priceConfigName,
      config: {
        ISO: isoConfig,
        PriceZone: priceZone,
        PPAType: ppaType,
        TunePreference: hashPriceMode ? "Auto" : "Manual",
        EnergyPrice: inputEnergyPrice !== "" ? parseFloat(inputEnergyPrice) : 0,
        HashPrice: hashPriceinPHs !== "" ? parseFloat(hashPriceinPHs) : 0,
      },
    };
    const postConfig = await api.PostAutoTuneV2Config(token, priceInfo, "POST");
    console.log("handlePriceConfigConfirmation", postConfig);
    if (postConfig === undefined || !postConfig.hasOwnProperty("status")) {
      setSubmitMsg("unknown");
      setSuccessModalOpen(true);
    } else if (postConfig.status === "ok") {
      setSubmitMsg("priceok");
      setmanualMsg("Successfully created the configuration");
      setSuccessModalOpen(true);
    } else {
      setSubmitMsg("error");
      setmanualMsg(postConfig.msg);
      setSuccessModalOpen(true);
    }
    setPCLoading(false);
    setShowPopup(false);
  };

  const handlePriceConfigDeletion = async () => {
    if (priceConfigName === "") {
      setSubmitMsg("invalidpriceconfig");
      setSuccessModalOpen(true);
      setShowPopup2(false);
      return;
    }
    const token = await getAccessTokenSilently();
    setPCDeleteLoading(true);
    console.log("handlePriceConfigDeletion", priceConfigName);
    const priceInfo = {
      config_type: "priceConfig",
      config_name: priceConfigName,
    };
    const postConfig = await api.PostAutoTuneV2Config(
      token,
      priceInfo,
      "DELETE"
    );
    if (postConfig === undefined || !postConfig.hasOwnProperty("status")) {
      setSubmitMsg("unknown");
      setSuccessModalOpen(true);
    } else if (postConfig.status === "ok") {
      setSubmitMsg("priceok");
      setmanualMsg("Successfully deleted the configuration");
      setSuccessModalOpen(true);
      setPriceConfigName("");
      setPriceConfigName1("");
      setIsoConfig("");
      setPriceZone("");
      setPPAType("");
      setHashPriceMode(false);
      sethashPriceinPHs("");
      setinputEnergyPrice("");
      priceConfigNames.splice(priceConfigNames.indexOf(priceConfigName), 1);
      priceConfigNames.sort();
      setPriceConfigNames(priceConfigNames);
    } else {
      setSubmitMsg("error");
      if (
        postConfig.msg === "" ||
        postConfig.msg === undefined ||
        postConfig.msg === null
      ) {
        setmanualMsg("Failed to delete the configuration");
      } else {
        setmanualMsg(postConfig.msg);
      }
      setSuccessModalOpen(true);
    }
    setPCDeleteLoading(false);
    setShowPopup2(false);
  };

  const handleCancelConfirmation = () => {
    setShowPopup(false);
  };

  const handleCancelConfirmation2 = () => {
    setShowPopup2(false);
  };

  const handleinputEnergyPriceChange = (event) => {
    const value = event.target.value;
    if (value >= 0) {
      setinputEnergyPrice(value);
    }
  };

  const handleHashPriceinPHs = (event) => {
    const value = event.target.value;
    if (value >= 0) {
      sethashPriceinPHs(value);
    }
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const [isPCLoading, setPCLoading] = useState(false);
  const [isPCDeleteLoading, setPCDeleteLoading] = useState(false);
  const [isPCLoading1, setPCLoading1] = useState(false);

  const clearHandler = async () => {
    setPriceConfigName("");
    setPriceConfigName1("");
    setIsoConfig("");
    setPriceZone("");
    setPPAType("");
    setHashPriceMode(false);
    sethashPriceinPHs("");
    setinputEnergyPrice("");
    setSubmitMsg("");
    setSuccessModalOpen(false);
  };

  useEffect(() => {
    const runFn = async () => {
      const token = await getAccessTokenSilently();
      const isAllowed = await api.IsOperationAllowed(token);
      if (isAllowed === undefined) {
        validUser = 1;
        setSubmitMsg("something");
        setSuccessModalOpen(true);
        return;
      } else if (isAllowed.status === "error") {
        if (isAllowed.errorCode === 1007) {
          validUser = 1;
          setSubmitMsg("nouser");
          setSuccessModalOpen(true);
        } else if (isAllowed.errorCode === 1009) {
          validUser = 1;
          setSubmitMsg("noauth");
          setSuccessModalOpen(true);
        } else {
          validUser = 1;
          setSubmitMsg("fetchfailed");
          setSuccessModalOpen(true);
        }
        console.log("Operation Allowed", isAllowed);
        return;
      } else {
        validUser = 0;
        setSubmitMsg("ok");
        setSuccessModalOpen(true);
      }
      const deviceGroups = await api.GetDGNames(token);
      if (deviceGroups.status === "error") {
        if (deviceGroups.errorCode === 1007) {
          validUser = 1;
          setSubmitMsg("nouser");
          setSuccessModalOpen(true);
        } else {
          validUser = 1;
          setSubmitMsg("fetchfailed");
          setSuccessModalOpen(true);
        }
      } else {
        const groupList = Object.keys(deviceGroups);
        var minerGroupList = [];
        for (let i = 0; i < groupList.length; i++) {
          minerGroupList.push(groupList[i]);
        }
        minerGroupList.sort();
        setMinerGroups(minerGroupList);
        localStorage.setItem(PRICE_GROUP_LIST, JSON.stringify(minerGroupList));
        validUser = 0;
      }
      const priceInfo = {
        config_type: "priceConfig",
      };
      const priceConfigNames = await api.PostAutoTuneV2Config(
        token,
        priceInfo,
        "GETALL"
      );
      if (priceConfigNames.status === "error") {
        if (priceConfigNames.errorCode === 1007) {
          validUser = 1;
          setSubmitMsg("nouser");
          setSuccessModalOpen(true);
        } else {
          validUser = 1;
          setSubmitMsg("fetchfailed");
          setSuccessModalOpen(true);
        }
      } else {
        const pcNames = priceConfigNames.result.reduce((PCList, item) => {
          if (item.priceConfig) {
            Object.keys(item.priceConfig).forEach((name) => {
              PCList.push(name);
            });
          }
          return PCList;
        }, []);
        // console.log('priceConfigNames', pcNames)
        pcNames.sort();
        setPriceConfigNames(pcNames);
        validUser = 0;
      }
    };
    runFn();
  }, [getAccessTokenSilently]);

  const attachPriceConfig = async () => {
    console.log("attachPriceConfig", applyGroups);
    await applyPriceConfigHandler(applyGroups, true);
    setApplyPCLoading(false);
    setShowPopup(false);
    return;
  };

  const detachPriceConfig = async () => {
    console.log("detachPriceConfig", applyGroups);
    await applyPriceConfigHandler(applyGroups, false);
    setDetachPCLoading(false);
    setShowPopup(false);
    return;
  };

  const handleIndexPPAType = async () => {
    setIndexPPALoading(true);
    console.log("handleIndexPPAType", ppaType, priceZone);
    if (priceZone === "") {
      setinputEnergyPrice("");
      setSubmitMsg("invalidpricezone");
      setSuccessModalOpen(true);
      setIndexPPALoading(false);
      return;
    }
    if (isoConfig === "") {
      setinputEnergyPrice("");
      setSubmitMsg("invalidISO");
      setSuccessModalOpen(true);
      setIndexPPALoading(false);
      return;
    }
    try {
      const token = await getAccessTokenSilently();
      const priceInfo = {
        ISO: isoConfig,
        priceZone: priceZone,
      };
      const getPrice = await api.GetEnergyPrice(token, priceInfo);
      if (!getPrice || !getPrice.status || getPrice.status !== "ok") {
        console.log("Failed to get the energy price");
        setinputEnergyPrice("");
        setSubmitMsg("unknown");
      } else {
        console.log("GetEnergyPrice response:", JSON.stringify(getPrice));
        if (
          getPrice.data !== undefined &&
          getPrice.data !== null &&
          getPrice.data[isoConfig] !== undefined &&
          getPrice.data[isoConfig] !== null &&
          getPrice.data[isoConfig][priceZone.toUpperCase()] !== undefined &&
          getPrice.data[isoConfig][priceZone.toUpperCase()].energyPrice !== null
        ) {
          const EnergyPrice =
            getPrice.data[isoConfig][priceZone.toUpperCase()].energyPrice;
          setinputEnergyPrice(EnergyPrice);
        }
        setSubmitMsg("ok");
      }
    } catch (error) {
      console.error("Error while fetching energy price:", error);
      setinputEnergyPrice("");
      setSubmitMsg("unknown");
    }
    setSuccessModalOpen(true);
    setIndexPPALoading(false);
  };

  if (validUser === 0) {
    return (
      <PageContainer
        title="Price"
        description="this is the price configuration page"
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="h4"
            component="div"
            sx={{ flexGrow: 1, textAlign: "center", color: "#007bff", mb: 4 }}
          >
            Price Configuration
          </Typography>
          <br />
          <br />
          <br />
          <Paper
            elevation={2}
            sx={{ width: "60%", padding: "24px 24px", borderRadius: "1rem" }}
          >
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                  <Typography variant="p" component="div" fontWeight={"bold"}>
                    Price Config Name
                  </Typography>
                  <Box display={"flex"} flexDirection={"row"} gap={2}>
                    <Autocomplete
                      disablePortal
                      onChange={(event, value) => handlePriceConfigName1(value)}
                      fullWidth
                      disableClearable
                      id="mode"
                      value={priceConfigName1}
                      options={priceConfigNames}
                      getOptionLabel={(option) => option}
                      renderOption={(props, option, { selected }) => (
                        <li
                          {...props}
                          style={{
                            whiteSpace: "normal",
                            fontSize: "16px",
                          }}
                        >
                          {option}
                        </li>
                      )}
                      getOptionSelected={(option, value) => option === value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              fontSize: "14px",
                            },
                          }}
                          size="small"
                          placeholder="Select price config"
                          aria-label="Select mode"
                        />
                      )}
                    />
                    <IconButton onClick={handleGetPriceConfigNames}>
                      <ReplayIcon sx={{ fontSize: "25px" }} />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                  <Typography variant="p" component="div" fontWeight={"bold"}>
                    Create New Price Config Name
                  </Typography>
                  <Box display={"flex"} flexDirection={"row"} gap={2}>
                    <TextField
                      label="Enter New Name"
                      hiddenLabel
                      size="small"
                      fullWidth
                      type="text"
                      value={priceConfigName}
                      onChange={(event) => handlePriceConfigName(event)}
                      margin="dense"
                      variant="outlined"
                      inputProps={{
                        sx: {
                          color: "black",
                          fontSize: "15px",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                  <Typography variant="p" component="div" fontWeight={"bold"}>
                    Independent System Operator
                  </Typography>
                  <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <Autocomplete
                      disablePortal
                      onChange={(event, value) => handleIsoConfig(value)}
                      fullWidth
                      disableClearable
                      id="mode"
                      value={isoConfig}
                      options={isoConfigOptions}
                      getOptionSelected={(option, value) => option === value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              fontSize: "14px",
                            },
                          }}
                          size="small"
                          placeholder="Select system operator"
                          aria-label="Select mode"
                        />
                      )}
                    />

                    <Box fullWidth>
                      <p
                        style={{
                          fontStyle: "italic",
                          color: "gray",
                          fontSize: "12px",
                          lineHeight: 1.35,
                          letterSpacing: 0.3,
                        }}
                      >
                        <strong>Note: </strong>If you are not enrolled with ISO,
                        please chose the 'NONE' option.
                      </p>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {isoConfig === "ERCOT" && (
                <>
                  <Grid item xs={12}>
                    <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                      <Typography
                        variant="p"
                        component="div"
                        fontWeight={"bold"}
                      >
                        Price Zone
                      </Typography>
                      <Box display={"flex"} flexDirection={"row"} gap={2}>
                        <Autocomplete
                          disablePortal
                          onChange={(event, value) => handlePriceZone(value)}
                          fullWidth
                          disableClearable
                          id="mode"
                          value={priceZone}
                          options={priceZoneOptions}
                          getOptionSelected={(option, value) =>
                            option === value
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                style: {
                                  fontSize: "14px",
                                },
                              }}
                              size="small"
                              placeholder="Select Price Zone"
                              aria-label="Select mode"
                            />
                          )}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                  <Typography variant="p" component="div" fontWeight={"bold"}>
                    Tune Preference
                  </Typography>

                  <Box display={"flex"} flexDirection={"row"} gap={2}>
                    <label
                      className={`radio-button ${
                        hashPriceMode ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        value="true"
                        name="HashPriceMode"
                        checked={hashPriceMode}
                        onChange={handleHashPriceMode}
                      />
                      <div className="radio-button-indicator"></div>
                    </label>
                    <Typography
                      variant="body1"
                      component="div"
                      sx={{ mr: 5, fontSize: "15px" }}
                    >
                      Auto
                    </Typography>

                    <label
                      className={`radio-button ${
                        !hashPriceMode ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        value="false"
                        name="HashPriceMode"
                        checked={!hashPriceMode}
                        onChange={handleHashPriceMode}
                      />
                      <div className="radio-button-indicator"></div>
                    </label>
                    <Typography
                      variant="body1"
                      component="div"
                      sx={{ fontSize: "15px" }}
                    >
                      Manual
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {(isoConfig === "ERCOT" || isoConfig === "NONE") && (
                <>
                  {isoConfig === "ERCOT" && (
                    <>
                      <Grid item xs={12}>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={0.5}
                        >
                          <Typography
                            variant={"p"}
                            component={"div"}
                            fontWeight={"bold"}
                          >
                            Power Purchase Agreement Type
                          </Typography>
                          <Box display={"flex"} flexDirection={"row"} gap={2}>
                            <Autocomplete
                              disablePortal
                              onChange={(event, value) => handlePPAType(value)}
                              fullWidth
                              disableClearable
                              id="mode"
                              value={ppaType}
                              options={ppaTypeOptions}
                              getOptionSelected={(option, value) =>
                                option === value
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    style: {
                                      fontSize: "14px",
                                    },
                                  }}
                                  size="small"
                                  placeholder="Select Agreement Type"
                                  aria-label="Select mode"
                                />
                              )}
                            />
                            {ppaType === "Index" && (
                              <IconButton
                                onClick={handleIndexPPAType}
                                disabled={isIndexPPALoading}
                                sx={{ ml: 2, mt: -1 }}
                              >
                                {isIndexPPALoading ? (
                                  <CircularProgress size={24} />
                                ) : (
                                  <ReplayIcon sx={{ fontSize: "25px" }} />
                                )}
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      </Grid>

                      {ppaType === "Hedge" && (
                        <>
                          <Grid item xs={12}>
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              gap={0.5}
                            >
                              <Typography
                                variant={"p"}
                                component={"div"}
                                fontWeight={"bold"}
                              >
                                Electricity Cost per MW per hour
                              </Typography>
                              <Box
                                display={"flex"}
                                flexDirection={"row"}
                                gap={2}
                              >
                                <TextField
                                  type="text"
                                  fullWidth
                                  value={inputEnergyPrice}
                                  helperText="Amount in USD"
                                  FormHelperTextProps={{
                                    style: {
                                      margin: 0,
                                      marginTop: "0.625rem",
                                    },
                                  }}
                                  onChange={handleinputEnergyPriceChange}
                                  margin="dense"
                                  variant="outlined"
                                  inputProps={{
                                    sx: {
                                      color: "black",
                                      fontSize: "15px",
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </>
                      )}

                      {ppaType === "Index" && inputEnergyPrice !== "" && (
                        <>
                          <Grid item xs={12}>
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              gap={0.5}
                            >
                              <Typography
                                variant={"p"}
                                component={"div"}
                                fontWeight={"bold"}
                              >
                                Electricity Cost per MW per hour
                              </Typography>
                              <Box
                                display={"flex"}
                                flexDirection={"row"}
                                gap={2}
                              >
                                <TextField
                                  type="text"
                                  fullWidth
                                  value={inputEnergyPrice}
                                  helperText="Amount in USD"
                                  FormHelperTextProps={{
                                    style: {
                                      margin: 0,
                                      marginTop: "0.625rem",
                                    },
                                  }}
                                  InputProps={{ readOnly: true }}
                                  margin="dense"
                                  variant="outlined"
                                  inputProps={{
                                    sx: {
                                      color: "black",
                                      fontSize: "15px",
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </>
                      )}
                    </>
                  )}

                  {isoConfig === "NONE" && (
                    <>
                      <Grid item xs={12}>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={0.5}
                        >
                          <Typography
                            variant={"p"}
                            component={"div"}
                            fontWeight={"bold"}
                          >
                            Power Purchase Agreement Type
                          </Typography>
                          <Box display={"flex"} flexDirection={"row"} gap={2}>
                            <Autocomplete
                              disablePortal
                              onChange={(event, value) => handlePPAType(value)}
                              fullWidth
                              disableClearable
                              id="mode"
                              value={ppaType}
                              options={ppaTypeOptions_NOISO}
                              getOptionSelected={(option, value) =>
                                option === value
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    style: {
                                      fontSize: "14px",
                                    },
                                  }}
                                  size="small"
                                  placeholder="Select Agreement Type"
                                  aria-label="Select mode"
                                />
                              )}
                            />
                            {ppaType === "Index" && (
                              <IconButton
                                onClick={handleIndexPPAType}
                                disabled={isIndexPPALoading}
                              >
                                {isIndexPPALoading ? (
                                  <CircularProgress size={24} />
                                ) : (
                                  <ReplayIcon sx={{ fontSize: "25px" }} />
                                )}
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      </Grid>

                      {ppaType === "Hedge" && (
                        <>
                          <Grid item xs={12}>
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              gap={0.5}
                            >
                              <Typography
                                variant={"p"}
                                component={"div"}
                                fontWeight={"bold"}
                              >
                                Electricity Cost per MW per hour
                              </Typography>
                              <Box
                                display={"flex"}
                                flexDirection={"row"}
                                gap={2}
                              >
                                <TextField
                                  type="text"
                                  fullWidth
                                  value={inputEnergyPrice}
                                  helperText="Amount in USD"
                                  FormHelperTextProps={{
                                    style: {
                                      margin: 0,
                                      marginTop: "0.625rem",
                                    },
                                  }}
                                  onChange={handleinputEnergyPriceChange}
                                  margin="dense"
                                  variant="outlined"
                                  inputProps={{
                                    sx: {
                                      color: "black",
                                      fontSize: "15px",
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </>
                      )}

                      {ppaType === "Index" && inputEnergyPrice !== "" && (
                        <>
                          <Grid item xs={12}>
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              gap={0.5}
                            >
                              <Typography
                                variant={"p"}
                                component={"div"}
                                fontWeight={"bold"}
                              >
                                Electricity Cost per MW per hour
                              </Typography>
                              <Box
                                display={"flex"}
                                flexDirection={"row"}
                                gap={2}
                              >
                                <TextField
                                  type="text"
                                  fullWidth
                                  value={inputEnergyPrice}
                                  helperText="Amount in USD"
                                  FormHelperTextProps={{
                                    style: {
                                      margin: 0,
                                      marginTop: "0.625rem",
                                    },
                                  }}
                                  InputProps={{ readOnly: true }}
                                  variant="outlined"
                                  inputProps={{
                                    sx: {
                                      color: "black",
                                      fontSize: "15px",
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </>
              )}



              <Grid item xs={12}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  gap={1}
                  justifyContent={"flex-end"}
                >
                  <Button
                    onClick={() => {
                      setShowPopup2(true);
                    }}
                    type="submit"
                    variant="contained"
                    disabled={isPCDeleteLoading}
                    sx={{ width: 100 }}
                  >
                    {isPCDeleteLoading ? "In Progress..." : "Delete"}
                  </Button>
                  <Button
                    onClick={clearHandler}
                    type="submit"
                    variant="contained"
                    sx={{ width: 100 }}
                  >
                    Clear
                  </Button>

                  {/* <Button onClick={handlePriceConfigGet} type="submit" variant="contained" disabled={isPCLoading1} sx={{ mr: 2 }}>
                  {isPCLoading1 ? 'In Progress...' : 'Get'}
              </Button> */}
                  <Button
                    sx={{ width: 100 }}
                    onClick={() => {
                      setShowPopup(true);
                    }}
                    type="submit"
                    variant="contained"
                    disabled={isPCLoading}
                  >
                    {isPCLoading ? "In Progress..." : "Save"}
                  </Button>
                  {/* Confirmation dialog in case of Manual Tune */}
                  {showPopup && (
                    <div className="popup">
                      <div className="popup-content">
                        <div
                          className="close-icon"
                          onClick={() => setShowPopup(false)}
                        >
                          <FaTimes />
                        </div>
                        <p>Are you sure you want to proceed with Save?</p>
                        <div className="button-container">
                          <button onClick={handleCancelConfirmation}>No</button>
                          <button onClick={handlePriceConfigConfirmation}>
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {showPopup2 && (
                    <div className="popup">
                      <div className="popup-content">
                        <div
                          className="close-icon"
                          onClick={() => setShowPopup2(false)}
                        >
                          <FaTimes />
                        </div>
                        <p>
                          Are you sure you want to proceed with Deleting this
                          Price Config?
                        </p>
                        <div className="button-container">
                          <button onClick={handleCancelConfirmation2}>
                            No
                          </button>
                          <button onClick={handlePriceConfigDeletion}>
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    <SuccessModal
                      isOpen={successModalOpen}
                      onClose={handleCloseSuccessModal}
                      Status={submitMsg}
                      ManualTuneMsg={manualMsg}
                    />
                  </div>
                </Box>
              </Grid>
              {priceConfigName !== "" && (
                <Grid item xs={12}>
                  <Typography variant="body2">
                  Please save the new configuration before attaching/detaching to groups
                  </Typography>
                  <Box
                    padding={2}
                    display={"flex"}
                    border={"5px dashed rgb(224, 226, 236)"}
                    borderRadius={"0.5rem"}
                    flexDirection={"column"}
                    gap={0.5}
                  >
                    <Typography
                      variant={"p"}
                      component={"div"}
                      fontWeight={"bold"}
                    >
                      Attach/Detach Price Config to Groups
                    </Typography>

                    <Box display={"flex"} flexDirection={"column"} flex={2}>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        gap={0.5}
                        alignItems={"center"}
                      >
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={selectAll}
                          onChange={(event) => handleSelectAll(event)}
                        />
                        <Typography variant="caption">Select All</Typography>
                      </Box>
                      <Autocomplete
                        multiple
                        value={applyGroups}
                        onChange={(event, value) => applyVal(value)}
                        fullWidth
                        disableCloseOnSelect
                        id="combo-box-demo3"
                        options={minerGroups}
                        getOptionLabel={(option) => option}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            placeholder="Select Miner Groups"
                            aria-label="Select mode"
                            inputProps={{
                              ...params.inputProps,
                              style: {
                                fontSize: "14px",
                              },
                            }}
                          />
                        )}
                        // renderTags={() => null}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        defaultValue={[]}
                      />
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      gap={1}
                      justifyContent={"flex-end"}
                    >
                      {applyGroups.length > 0 && (
                        <>
                          <Button
                            onClick={attachPriceConfig}
                            variant="contained"
                            disabled={isApplyPCLoading}
                            sx={{ width: 135 }}
                          >
                            {isApplyPCLoading
                              ? "In Progress..."
                              : "Attach & Tune"}
                          </Button>
                          <Button
                            onClick={detachPriceConfig}
                            variant="contained"
                            disabled={isDetachPCLoading}
                            sx={{ width: 100 }}
                          >
                            {isDetachPCLoading ? "In Progress..." : "Detach"}
                          </Button>
                        </>
                      )}
                      {applyGroups.length <= 0 && (
                        <>
                          <Button
                            onClick={attachPriceConfig}
                            variant="contained"
                            disabled={true}
                            sx={{ width: 135 }}
                          >
                            Attach & Tune
                          </Button>
                          <Button
                            onClick={detachPriceConfig}
                            variant="contained"
                            disabled={true}
                            sx={{ width: 100 }}
                          >
                            Detach
                          </Button>
                        </>
                      )}
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Paper>
          <br />
          <Footer sub={true} />
        </Box>
      </PageContainer>
    );
  } else if (validUser !== -1 && validUser !== 0) {
    return (
      <PageContainer
        title="Price"
        description="this is the price configuration page"
      >
        <div>
          <SuccessModal
            isOpen={successModalOpen}
            onClose={handleCloseSuccessModal}
            Status={submitMsg}
          />
        </div>
      </PageContainer>
    );
  } else {
    return (
      <PageContainer
        title="Price"
        description="this is the price configuration page"
      >
        <PageLoading />
      </PageContainer>
    );
  }
};

export default PriceConfig;
